import { useQuery } from "@apollo/react-hooks";
import { Box, Flex } from "@theme-ui/components";
import React from "react";

import { SelectField, SwitchField, TextField } from "@escola/fields";
import { SCHEDULERS_QUERY } from "@escola/graphql";

import { RichTextField } from "app/fields";

export const streamSources = [
  {
    id: "Youtube",
    name: "Youtube",
  },
];

export const LiveForm = ({ isDisabled = false }) => {
  const { data } = useQuery(SCHEDULERS_QUERY);
  return (
    <Box>
      <Flex sx={{ mb: 4, justifyContent: "flex-start" }}>
        <SwitchField
          disabled={isDisabled}
          name="active"
          label="Transmissão Online"
        />
      </Flex>
      <SelectField
        label="Provedor de Streaming"
        name="streamSource"
        customEmpty={true}>
        {streamSources.map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </SelectField>
      <TextField label="Streaming ID" name="streamId" />

      <SelectField label="Evento da agenda" name="scheduler">
        {data?.schedulers.map(({ id, name, ...values }) => (
          <option key={id} value={id}>
            {values.class
              ? `Aula ${values.class.number} - ${values.class.subject.name}`
              : name}
          </option>
        ))}
      </SelectField>
      <RichTextField label="Texto informativo quando offline" name="text" />
    </Box>
  );
};
