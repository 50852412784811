import cloneDeep from "lodash.clonedeep";
import { object, string, number } from "yup";

import { CLASSES_QUERY } from "@escola/graphql";

import { streamSources } from "app/live/LiveForm";

export const validationSchema = object().shape({
  number: string().required("Obrigatório"),
  subject: number().required("Obrigatório"),
  teacher: number().required("Obrigatório"),
  videoSource: string().nullable(),
  video: string().nullable(),
  alternativeStreaming: string().nullable(),
});

export const initialValues: any = {
  number: "",
  subject: "",
  teacher: "",
  videoSource: streamSources[0].id,
  video: "",
  alternativeStreaming: "",
};

export const validationMidiaSchema = object().shape({
  videoSource: string().nullable(),
  video: string().nullable(),
  alternativeStreaming: string().nullable(),
});

export const initialMidiaValues: any = {
  videoSource: streamSources[0].id,
  video: "",
  alternativeStreaming: "",
};

export const options = {
  refetchQueries: [
    {
      query: CLASSES_QUERY,
    },
  ],
};

export const deserializeClass = (data) => {
  const values = cloneDeep(data);
  values.subject = data.subject?.id;
  values.teacher = data.teacher?.id;

  return values;
};

export const serializeMember = (data) => {
  const { __typename, id, ...values } = data;

  return values;
};
