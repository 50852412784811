import React from "react";
import { Switch, Route } from "react-router-dom";

import { TeacherAdd } from "./TeacherAdd";
import { TeacherList } from "./TeacherList";
import { TeacherView } from "./TeacherView";

export enum TEACHER_ROUTES {
  list = `/professores`,
  edit = `/professores/:id`,
  add = `/professores/novo`,
}

export default () => (
  <Switch>
    <Route exact path={TEACHER_ROUTES.list} component={TeacherList} />
    <Route exact path={TEACHER_ROUTES.add} component={TeacherAdd} />
    <Route exact path={TEACHER_ROUTES.edit} component={TeacherView} />
  </Switch>
);
