import { useQuery } from "@apollo/react-hooks";
import { useFormikContext } from "formik";
import React from "react";
import { Box, Grid } from "@theme-ui/components";

import {
  CheckField,
  DateTimeField,
  SelectField,
  TextField,
} from "@escola/fields";
import { CLASSES_QUERY } from "@escola/graphql";

export const SchedulerForm = ({ isDisabled = false }) => {
  const { values, ...rest } = useFormikContext<any>();
  const { data } = useQuery(CLASSES_QUERY);
  return (
    <Box>
      <CheckField label="É uma aula?" name="isClass" />
      <Box sx={{ mb: 2, px: 2 }}>
        {!values.isClass && <TextField label="Nome" name="name" required />}
        {values.isClass && (
          <SelectField label="Aula" name="class" required>
            {data?.classes.map(({ id, subject, number }) => (
              <option key={id} value={id}>
                Aula {number} - {subject.name}
              </option>
            ))}
          </SelectField>
        )}
      </Box>
      <Grid
        sx={{
          gridTemplateColumns: ["1fr", null, "1fr 1fr"],
          rowGap: 2,
          columnGap: 4,
          mb: 2,
          px: 2,
        }}>
        <Box sx={{ gridColumn: 1 }}>
          <DateTimeField
            label="Data e hora de início"
            name="dateStart"
            required
          />
        </Box>
        <Box sx={{ gridColumn: [1, null, 2] }}>
          <DateTimeField label="Data e hora de fim" name="dateEnd" />
        </Box>
      </Grid>
    </Box>
  );
};
