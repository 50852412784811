import { useQuery } from "@apollo/react-hooks";
import React from "react";
import { Switch, Route } from "react-router-dom";

import { ME_QUERY } from "@escola/graphql";

import { AulaAdd } from "./AulaAdd";
import { AulaList } from "./AulaList";
import { AulaView } from "./AulaView";
import { AulaViewMidia } from "./AulaViewMidia";
import { Roles } from "app/layout/Sidebar";

export enum AULA_ROUTES {
  list = `/aulas`,
  edit = `/aulas/:id`,
  add = `/aulas/novo`,
}

export default () => {
  const { data, loading } = useQuery(ME_QUERY);

  if (loading) return null;

  const role = data.me.role.name;

  const Aula = role === Roles.Midia ? AulaViewMidia : AulaView;

  return (
    <Switch>
      <Route exact path={AULA_ROUTES.list} component={AulaList} />
      <Route exact path={AULA_ROUTES.add} component={AulaAdd} />
      <Route exact path={AULA_ROUTES.edit} component={Aula} />
    </Switch>
  );
};
