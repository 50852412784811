import cloneDeep from "lodash.clonedeep";
import { object, string } from "yup";

import { IS_OPEN } from "@escola/graphql";

export const validationSchema = object().shape({
  open: string().required("Obrigatório"),
  text: string(),
});

export const initialValues: any = {
  open: false,
  text: "",
};

export const options = {
  refetchQueries: [
    {
      query: IS_OPEN,
    },
  ],
};

export const deserializeLive = (data) => {
  const values = cloneDeep(data);
  return values;
};

export const serializeLive = (data) => {
  const { __typename, id, ...values } = data;
  return values;
};
