import cloneDeep from "lodash.clonedeep";
import { object, string } from "yup";

import { LIVE_QUERY } from "@escola/graphql";

export const validationSchema = object().shape({
  active: string().required("Obrigatório"),
  streamSource: string(),
  streamId: string().nullable(),
  alternativeStreaming: string().nullable(),
  scheduler: string().nullable(),
});

export const initialValues: any = {
  active: false,
  streamSource: "Youtube",
  streamId: "",
  alternativeStreaming: "",
  scheduler: "",
};

export const options = {
  refetchQueries: [
    {
      query: LIVE_QUERY,
    },
  ],
};

export const deserializeLive = (data) => {
  const values = cloneDeep(data);
  if (values.scheduler) {
    values.scheduler = values.scheduler.id;
  }

  return values;
};

export const serializeLive = (data) => {
  const { __typename, id, scheduler, ...values } = data;

  if (scheduler) {
    values.scheduler = scheduler;
  } else {
    values.scheduler = null;
  }

  return values;
};
