import { useMutation } from "@apollo/react-hooks";
import { useDisclosure } from "@chakra-ui/core";
import * as Sentry from "@sentry/browser";
import React, { useEffect } from "react";
import { Button, Flex } from "@theme-ui/components";

import {
  DELETE_STUDENT_MUTATION,
  UPDATE_STUDENT_MUTATION,
  STUDENT_QUERY,
  CONFIRM_USER,
} from "@escola/graphql";

import { StudentDelete } from "./StudentDelete";
import { StudentForm } from "./StudentForm";
import { STUDENT_ROUTES } from "./StudentRoutes";
import {
  validationSchema,
  deserializeStudent,
  serializeUpdateStudent,
} from "./StudentUtils";
import { options } from "./StudentUtils";
import { BaseView } from "app/baseView";
import { ConfirmModal } from "app/modal";
import { useAlertSuccess, useAlertFail, useRedirect } from "app/utils";

const Actions = ({ id, state, refetch }) => {
  const setRedirect = useRedirect();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [confirmUser, { loading, data, error }] = useMutation(CONFIRM_USER, {
    refetchQueries: [{ query: STUDENT_QUERY, variables: { where: { id } } }],
  });
  const alertSuccess = useAlertSuccess();
  const alertFail = useAlertFail();

  const confirmUserHandler = () => {
    confirmUser({
      variables: { input: { data: { id } } },
    }).catch((e) => {
      Sentry.withScope(function (scope) {
        scope.setLevel(Sentry.Severity.Error);
        Sentry.captureException(e);
      });
    });
  };

  useEffect(() => {
    if (data?.confirmUser.id) {
      alertSuccess("Inscrição confirmada");
      setRedirect(STUDENT_ROUTES.list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (error) {
      alertFail("Erro ao confirmar inscrição");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Flex sx={{ flex: "1 1 auto" }}>
      {!state.confirmed && (
        <Button
          variant="success"
          disabled={loading}
          onClick={onOpen}
          type="button">
          Confirmar Inscrição
        </Button>
      )}
      {state.confirmed && (
        <Button
          variant="success"
          disabled={loading}
          onClick={onOpen}
          type="button">
          Reenviar E-mail de Confirmação
        </Button>
      )}
      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        title="Confirmar Inscrição?"
        text={`Ao confirmar a inscrição ${state.name} receberá um e-mail com o link de acesso para definir a senha e passar a ter acesso a EB Virtual.`}
        onConfirm={confirmUserHandler}
      />
    </Flex>
  );
};

export const StudentView = (props) => {
  const title = "Editar ALuno";
  const id = props.match.params.id;
  const query = STUDENT_QUERY;
  const updateMutation = UPDATE_STUDENT_MUTATION;
  const deleteMutation = DELETE_STUDENT_MUTATION;
  const routes = STUDENT_ROUTES;
  const queryItem = "users";
  const deserialize = deserializeStudent;
  const serialize = serializeUpdateStudent;
  const updateInput = "updateUserInput";
  const deleteInput = "deleteUserInput";
  const schema = validationSchema;
  const ItemDelete = StudentDelete;
  const ItemForm = StudentForm;
  const mutationOptions = options;

  return (
    <BaseView
      id={id}
      title={title}
      query={query}
      updateMutation={updateMutation}
      deleteMutation={deleteMutation}
      routes={routes}
      queryItem={queryItem}
      deserialize={deserialize}
      serialize={serialize}
      updateInput={updateInput}
      deleteInput={deleteInput}
      validationSchema={schema}
      ItemDelete={ItemDelete}
      ItemForm={ItemForm}
      mutationOptions={mutationOptions}
      Actions={Actions}
    />
  );
};

export default StudentView;
