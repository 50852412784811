import React from "react";

import { TEACHERS_QUERY } from "@escola/graphql";

import { TEACHER_ROUTES } from "./TeacherRoutes";
import { BaseList } from "app/baseView";

export const TeacherList = () => {
  const title = "Professores";
  const query = TEACHERS_QUERY;
  const routes = TEACHER_ROUTES;
  const columns = React.useMemo(
    () => [
      {
        Header: null,
        id: "teachers",
        columns: [
          {
            Header: "Nome",
            accessor: "name",
            Cell: ({ row }) => row.original.name,
          },
        ],
      },
    ],
    []
  );

  return (
    <BaseList
      query={query}
      columns={columns}
      routes={routes}
      title={title}
      queryItem="teachers"
    />
  );
};

export default TeacherList;
