import { object, string } from "yup";

import { NOTIFICATIONS_QUERY } from "@escola/graphql";

export const validationSchema = object().shape({
  subject: string().required("Obrigatório"),
  content: string().required("Obrigatório"),
});

export const initialValues: any = {
  subject: "",
  content: "",
};

export const options = {
  refetchQueries: [
    {
      query: NOTIFICATIONS_QUERY,
    },
  ],
};

export const deserializeNotification = (data) => {
  return data;
};

export const serializeNotification = (data) => {
  const { __typename, id, created_at, ...values } = data;

  return values;
};
