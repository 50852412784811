import { useQuery } from "@apollo/react-hooks";
import {
  Flex,
  Text,
  Badge,
  Button,
  Spinner,
  Label,
} from "@theme-ui/components";
import { saveAs } from "file-saver";
import React, { useState } from "react";

import { currentConfig } from "@escola/config";
import { GET_ATTENDANCE_SUMMARY } from "@escola/graphql";
import { SpinnerAbsolute, Table } from "@escola/layout";

export const DownloadCertificado = ({ name }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const download = () => {
    setLoading(true);
    setError(false);

    const body = new FormData();
    body.append("name", name);

    fetch(currentConfig.CERTIFICATE_URL, {
      method: "POST",
      body,
    })
      .then((res: any) => res.blob())
      .then((blob) => {
        setLoading(false);
        saveAs(blob, `${name} - 19EB Certificado.pdf`);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <Flex
      sx={{
        position: "relative",
        alignItems: "center",
      }}>
      <Button onClick={download} disabled={loading} variant="small">
        <Flex sx={{ alignItems: "center" }}>
          <Text sx={{ mr: loading ? 2 : 0 }}>Download Certificado</Text>
          {loading && <Spinner strokeWidth={4} size={20} />}
        </Flex>
      </Button>
      {error && (
        <Label variant="label.error" sx={{ mt: 2, fontSize: 1 }}>
          Erro ao fazer o download, tente novamente.
        </Label>
      )}
    </Flex>
  );
};

export const AttendanceList = () => {
  const { data, loading } = useQuery(GET_ATTENDANCE_SUMMARY);
  const columns = React.useMemo(
    () => [
      {
        Header: null,
        id: "aulas",
        columns: [
          {
            Header: "Nome",
            accessor: "user.name",
            enableFilters: true,
          },
          {
            Header: "Visualizadas",
            accessor: "completed",
            Cell: ({ row }) => row.original.completed,
          },
          {
            Header: "Visualizadas e não concluídas",
            accessor: "incompleted",
            Cell: ({ row }) => row.original.incompleted,
          },
          {
            Header: "Total não concluídas",
            accessor: "totalIncompleted",
            Cell: ({ row }) => row.original.totalIncompleted,
          },
          {
            Header: "Percentagem",
            accessor: "percentage",
            Cell: ({ row }) => {
              if (row.original.percentage >= 55) {
                return (
                  <Badge sx={{ bg: "success", fontSize: 3, px: 2 }}>
                    {row.original.percentage}%
                  </Badge>
                );
              }
              return (
                <Badge sx={{ bg: "warning", fontSize: 3, px: 2 }}>
                  {row.original.percentage}%
                </Badge>
              );
            },
          },
          {
            Header: "Certificado",
            Cell: ({ row }) => {
              if (row.original.percentage >= 55) {
                return <DownloadCertificado name={row.original.user.name} />;
              }
              return null;
            },
          },
        ],
      },
    ],
    []
  );

  return (
    <>
      <Flex
        variant="layout.admin.panel"
        sx={{
          justifyContent: "space-between",
          position: "sticky",
          top: 0,
          zIndex: 2,
          mb: 3,
          bg: "white",
          px: 3,
          py: 2,
        }}>
        <Text variant="styles.h2" sx={{ m: 0 }}>
          Assiduidade
        </Text>
      </Flex>
      {loading && <SpinnerAbsolute />}
      <Flex sx={{ overflowX: "scroll" }}>
        {data?.getAttendanceSummary && (
          <Table.TableUi
            columns={columns}
            data={data.getAttendanceSummary.data}
            clickedRow={false}
            onClick={() => null}
          />
        )}
      </Flex>
    </>
  );
};

export default AttendanceList;
