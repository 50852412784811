import { useQuery, useMutation } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { Box, Text, Button, Label, Flex } from "@theme-ui/components";
import { Formik, Form } from "formik";
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

import {
  CLASS_QUERY,
  DELETE_CLASS_MUTATION,
  UPDATE_CLASS_MUTATION,
} from "@escola/graphql";
import { Icon } from "@escola/icons";
import { SpinnerAbsolute } from "@escola/layout";

import { AulaDelete } from "./AulaDelete";
import { AulaForm } from "./AulaForm";
import { AULA_ROUTES } from "./AulaRoutes";
import {
  validationSchema,
  options,
  deserializeClass,
  serializeMember,
} from "./AulaUtils";
import { Card } from "app/layout/Card";
import { useRedirect, useAlertSuccess } from "app/utils";

export const AulaView = (props) => {
  const id = props.match.params.id;
  const setRedirect = useRedirect();
  const [state, setState] = useState<any>({});
  const { data, loading, refetch } = useQuery(CLASS_QUERY, {
    variables: { where: { id } },
  });

  const [upadateClass, { ...update }] = useMutation(
    UPDATE_CLASS_MUTATION,
    options
  );
  const isDisabled = loading || update.loading;
  const alertSuccess = useAlertSuccess();

  useEffect(() => {
    if (data?.classes[0]?.id) {
      const values = deserializeClass(data.classes[0]);
      setState(values);
    }
  }, [data]);

  const updateClassHandler = (data) => {
    const values = serializeMember(data);
    upadateClass({
      variables: {
        updateClassInput: { where: { id }, data: values },
      },
    })
      .then(() => {
        refetch({ where: { id } });
        alertSuccess("Aula autalizada com sucesso.");
        setRedirect(AULA_ROUTES.list);
      })
      .catch((e) => {
        Sentry.withScope(function (scope) {
          scope.setLevel(Sentry.Severity.Error);
          Sentry.captureException(e);
        });
      });
  };

  if (!data?.classes[0]?.id && !loading) {
    return <Redirect to={AULA_ROUTES.list} />;
  }

  return (
    <>
      {loading && <SpinnerAbsolute />}
      {state?.id && (
        <Card
          sx={{
            justifyContent: "space-between",
            position: "sticky",
            top: 0,
            zIndex: 2,
          }}>
          <Text variant="styles.h2" sx={{ mb: 4 }}>
            Aula {state.number}
          </Text>

          <Formik
            validateOnMount={false}
            initialValues={state}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              updateClassHandler(values);
            }}>
            {({ isValid }) => {
              return (
                <Form autoComplete="off" noValidate={true}>
                  <fieldset
                    disabled={isDisabled}
                    style={{ border: "none", padding: 0 }}>
                    <AulaForm isDisabled={isDisabled} />
                    <Box
                      sx={{
                        position: "fixed",
                        bottom: "0",
                        left: [0, null, "200px"],
                        right: 0,
                      }}>
                      <Card
                        sx={{
                          display: "flex",
                          mb: 0,
                          bg: "white",
                          width: "100%",
                          py: 3,
                          px: 4,
                          justifyContent: "flex-end",
                        }}>
                        {update.loading && <SpinnerAbsolute />}
                        <Box>
                          {update.error && (
                            <Label
                              variant="label.error"
                              sx={{
                                justifyContent: "center",
                                alignItems: "center",
                              }}>
                              Erro ao guardar, tente novamente...
                            </Label>
                          )}
                        </Box>
                        <Flex sx={{ alignItems: "center" }}>
                          <Button
                            type="button"
                            variant="outline"
                            sx={{ mr: [1, null, 3] }}
                            onClick={() => setRedirect(AULA_ROUTES.list)}>
                            <Flex sx={{ alignItems: "center" }}>
                              <Icon name="ArrowBack" />
                              <Text sx={{ mr: 2 }}>Voltar</Text>
                            </Flex>
                          </Button>
                          <AulaDelete
                            sx={{ mr: [1, null, 3] }}
                            disabled={isDisabled}
                            mutation={DELETE_CLASS_MUTATION}
                            variables={{
                              deleteClassInput: {
                                where: { id },
                              },
                            }}
                            onDelete={() => setRedirect(AULA_ROUTES.list)}>
                            Remover
                          </AulaDelete>
                          <Button
                            type="submit"
                            variant="primary"
                            disabled={!isValid || isDisabled}>
                            Guardar
                          </Button>
                        </Flex>
                      </Card>
                    </Box>
                  </fieldset>
                </Form>
              );
            }}
          </Formik>
        </Card>
      )}
    </>
  );
};

export default AulaView;
