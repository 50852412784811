import React from "react";

import { CREATE_STUDENT_MUTATION } from "@escola/graphql";

import { StudentFormAdd } from "./StudentFormAdd";
import { STUDENT_ROUTES } from "./StudentRoutes";
import {
  validationSchema,
  options,
  initialValues,
  serializeStudent,
} from "./StudentUtils";
import { BaseAdd } from "app/baseView";

export const StudentAdd = () => {
  const title = "Novo Aluno";
  const createMutation = CREATE_STUDENT_MUTATION;
  const routes = STUDENT_ROUTES;
  const createInput = "registerUser";
  const createItemInput = "registerUserInput";
  const schema = validationSchema;
  const ItemForm = StudentFormAdd;
  const itemName = "user";

  return (
    <BaseAdd
      title={title}
      createMutation={createMutation}
      routes={routes}
      createInput={createInput}
      createItemInput={createItemInput}
      validationSchema={schema}
      ItemForm={ItemForm}
      mutationOptions={options}
      initialValues={initialValues}
      itemName={itemName}
      serialize={serializeStudent}
    />
  );
};

export default StudentAdd;
