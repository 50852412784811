import { useQuery } from "@apollo/react-hooks";
import React, { useCallback, useEffect, useState } from "react";
import io from "socket.io-client";

import { currentConfig } from "@escola/config";
import { ME_QUERY } from "@escola/graphql";

import { useAuthentication } from "app/authentication/hooks";
import { usePageVisibility } from "app/utils/usePageVisibility";

export const SocketConnectionContext = React.createContext({});

export const SocketConnection = ({ children }) => {
  const [ws, setWs] = useState<any>(null);
  const [students, setStudents] = useState([]);
  const [users, setUsers] = useState([]);
  const [studentsIds, setStudentsIds] = useState<any>([]);
  const [usersIds, setUsersIds] = useState<any>([]);
  const [connected, setConnected] = useState<any>(false);
  const [connectionError, setConnectionError] = useState<any>(false);
  const isVisible = usePageVisibility();
  const [time, setTime] = useState<any>(null);
  const { data, loading } = useQuery(ME_QUERY);
  const { isAuthenticated } = useAuthentication();

  useEffect(() => {
    const t = setTimeout(() => {
      setTime(t);
      if (!ws) return;
      sendUserData();
    }, 10000);

    return () => {
      if (time) {
        clearTimeout(time);
      }
    };
  }, [time]);

  const checkConnection = () => {
    if (ws && !ws.connected) {
      ws.connect();
    }
  };

  const sendUserData = useCallback(() => {
    if (!loading && data?.me && ws) {
      ws.emit("users:connected", {
        id: data.me.id,
        name: data.me.name,
        local: "admin",
      });
    }
  }, [loading, data?.me, ws]);

  useEffect(() => {
    if (!connectionError) return;

    setTimeout(() => {
      if (ws && !ws.connected) {
        setConnected(false);
      }
    }, 10000);
  }, [connectionError]);

  useEffect(() => {
    if (connected) return;
    createConnection();
    return () => {
      if (ws) {
        ws.off("students:status");
        ws.off("notification");
      }
    };
  }, [connected]);

  useEffect(() => {
    if (isVisible) {
      checkConnection();
    }
  }, [ws, isVisible]);

  useEffect(() => {
    if (!ws) return;

    sendUserData();
  }, [ws, sendUserData]);

  const createConnection = () => {
    if (!isAuthenticated) return;

    const socket = io(`${currentConfig.WS_ENDPOINT}/users`, {
      transports: ["websocket"],
    });

    socket.on("students:status", (data) => {
      setStudents(data);
    });

    socket.on("users:status", (data) => {
      setUsers(data);
    });

    socket.on("connect", () => {
      setConnected(true);
      sendUserData();
    });

    socket.on("connect_error", () => {
      setConnectionError(true);
    });

    socket.on("disconnect", () => {
      socket.removeAllListeners();
      setConnected(false);
    });

    setWs(socket);
  };

  useEffect(() => {
    setStudentsIds(students.map((student: any) => student.id));
  }, [students]);

  useEffect(() => {
    setUsersIds(users.map((user: any) => user.id));
  }, [users]);

  if (loading) return null;

  return (
    <SocketConnectionContext.Provider
      value={{ ws, students, setStudents, studentsIds, usersIds, users }}>
      {children}
    </SocketConnectionContext.Provider>
  );
};
