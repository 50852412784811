import React from "react";
import { Box, Flex } from "@theme-ui/components";

import { SwitchField } from "@escola/fields";

import { RichTextField } from "app/fields";

export const SettingsForm = ({ isDisabled = false }) => {
  return (
    <Box>
      <Flex sx={{ mb: 4, justifyContent: "flex-start" }}>
        <SwitchField
          disabled={isDisabled}
          name="open"
          label="Ativar acesso ao conteúdo aos alunos"
          sx={{ flexDirection: ["column", null, "row"] }}
        />
      </Flex>
      <RichTextField
        label="Texto informativo quando acesso ao conteúdo estiver desativado"
        name="text"
      />
    </Box>
  );
};
