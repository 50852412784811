import { format, parseISO, parse } from "date-fns";
import cloneDeep from "lodash.clonedeep";
import { object, string, boolean, mixed } from "yup";

import { dateTime, dateNotRequired } from "@escola/fields";
import { SCHEDULERS_QUERY } from "@escola/graphql";

export const validationSchema = object().shape({
  dateStart: dateTime().required("Obrigatório"),
  dateEnd: dateNotRequired(),
  isClass: boolean(),
  class: mixed().when("isClass", {
    is: true,
    then: string(),
    otherwise: string().nullable(),
  }),
  name: string().when("isClass", {
    is: true,
    then: string().nullable(),
    otherwise: string().required("Obrigatório"),
  }),
});

export const initialValues: any = {
  name: "",
  isClass: false,
  dateStart: "",
  dateEnd: null,
  class: null,
};

export const options = {
  refetchQueries: [
    {
      query: SCHEDULERS_QUERY,
    },
  ],
};

export const deserializeScheduler = (data) => {
  const values = cloneDeep(data);

  values.dateStart = format(
    parseISO(values.dateStart.replace(".000Z", "")),
    "dd/MM/yyyy HH:mm"
  );

  if (data.dateEnd) {
    values.dateEnd = format(
      parseISO(data.dateEnd.replace(".000Z", "")),
      "dd/MM/yyyy HH:mm"
    );
  }

  if (values.class) {
    values.isClass = true;
    values.class = values.class.id;
  }

  return values;
};

export const serializeScheduler = (data) => {
  const { __typename, id, isClass, dateEnd, ...values } = data;

  if (values.dateStart) {
    values.dateStart = format(
      parse(values.dateStart, "dd/MM/yyyy HH:mm", new Date()),
      "yyyy-MM-dd'T'HH:mm:'00.000Z'"
    );
  }

  if (dateEnd) {
    values.dateEnd = format(
      parse(dateEnd, "dd/MM/yyyy HH:mm", new Date()),
      "yyyy-MM-dd'T'HH:mm:'00.000Z'"
    );
  } else {
    values.dateEnd = null;
  }

  if (!isClass) {
    values.class = null;
  } else {
    values.name = "";
  }

  return values;
};
