import React from "react";

import { CREATE_SCHEDULER_MUTATION } from "@escola/graphql";

import { SchedulerForm } from "./SchedulerForm";
import { SCHEDULER_ROUTES } from "./SchedulerRoutes";
import {
  validationSchema,
  options,
  initialValues,
  serializeScheduler,
} from "./SchedulerUtils";
import { BaseAdd } from "app/baseView";

export const SchedulerAdd = () => {
  const title = "Novo Item";
  const createMutation = CREATE_SCHEDULER_MUTATION;
  const routes = SCHEDULER_ROUTES;
  const createInput = "createScheduler";
  const createItemInput = "createSchedulerInput";
  const schema = validationSchema;
  const ItemForm = SchedulerForm;
  const itemName = "scheduler";
  const serialize = serializeScheduler;

  return (
    <BaseAdd
      serialize={serialize}
      title={title}
      createMutation={createMutation}
      routes={routes}
      createInput={createInput}
      createItemInput={createItemInput}
      validationSchema={schema}
      ItemForm={ItemForm}
      mutationOptions={options}
      initialValues={initialValues}
      itemName={itemName}
    />
  );
};

export default SchedulerAdd;
