import React from "react";
import { Switch, Route } from "react-router-dom";

import { SchedulerAdd } from "./SchedulerAdd";
import { SchedulerList } from "./SchedulerList";
import { SchedulerView } from "./SchedulerView";

export enum SCHEDULER_ROUTES {
  list = `/agenda`,
  edit = `/agenda/:id`,
  add = `/agenda/novo`,
}

export default () => (
  <Switch>
    <Route exact path={SCHEDULER_ROUTES.list} component={SchedulerList} />
    <Route exact path={SCHEDULER_ROUTES.add} component={SchedulerAdd} />
    <Route exact path={SCHEDULER_ROUTES.edit} component={SchedulerView} />
  </Switch>
);
