import cloneDeep from "lodash.clonedeep";
import { object, string } from "yup";

import { currentConfig } from "@escola/config";
import { file } from "@escola/fields";
import { TEACHERS_QUERY } from "@escola/graphql";

const MAX_SIZE = 1024 * 15;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
const uploadPrefix = currentConfig.UPLOAD_PREFIX;

export const validationSchema = object().shape({
  name: string().required("Obrigatório"),
  picture: file({ MAX_SIZE, SUPPORTED_FORMATS }),
});

export const initialValues: any = {
  name: "",
  picture: "",
};

export const options = {
  refetchQueries: [
    {
      query: TEACHERS_QUERY,
    },
  ],
};

export const deserializeTeacher = (data) => {
  const values = cloneDeep(data);

  if (values?.picture?.formats?.thumbnail.url) {
    if (!values.picture.formats.thumbnail.url.startsWith(uploadPrefix)) {
      values.picture.formats.thumbnail.url = `${uploadPrefix}${values.picture.formats.thumbnail.url}`;
    }
  }

  if (values?.picture?.url) {
    if (!values.picture.url.startsWith(uploadPrefix)) {
      values.picture.url = `${uploadPrefix}${values.picture.url}`;
    }
  }

  return values;
};

export const serializeTeacher = (data) => {
  const { __typename, id, ...values } = data;

  if (values.picture) {
    values.picture = values.picture.id;
  }

  return values;
};
