"use strict";

exports.__esModule = true;
exports.default = void 0;

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _components = require("@theme-ui/components");

var _styledSystem = require("styled-system");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Svg = (0, _styled.default)(_components.Box)({
  display: "inline-block",
  flex: "none",
  fill: "currentColor"
}, _styledSystem.size, _styledSystem.color);
Svg.displayName = "Svg";
Svg.defaultProps = {
  as: "svg"
};
var _default = Svg;
exports.default = _default;