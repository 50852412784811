import React from "react";
import { Box } from "@theme-ui/components";

import { PictureUploadField, TextField, FileUploadField } from "@escola/fields";

import { useToken } from "app/authentication/hooks";

export const FILE_SUPPORTED_FORMATS = ["application/pdf"];
export const FILE_MAX_SIZE = 1024 * 1024 * 40;

export const SubjectForm = ({ isDisabled = false }) => {
  const token = useToken();

  return (
    <Box>
      <TextField label="Nome" name="name" required />
      <PictureUploadField
        label="Imagem"
        name="image"
        placeholder="Carregue uma imagem para a capa"
        token={token}
        required
      />
      <FileUploadField
        label="Ficheiro"
        name="file"
        placeholder="Carregue um pdf para ficar disponível para o aluno"
        token={token}
        maxSize={FILE_MAX_SIZE}
        accept={FILE_SUPPORTED_FORMATS}
      />
    </Box>
  );
};
