"use strict";

exports.__esModule = true;
exports.default = void 0;

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _Area = _interopRequireDefault(require("./Area"));

var _templateObject;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GridLayout = (0, _styled.default)(_Area.default)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background-color: transparent;\n\n  grid-template-columns: 1fr;\n  grid-template-rows: 1fr;\n  grid-gap: 20px;\n\n  ", " {\n    height: 100%;\n    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));\n    grid-template-rows: repeat(2, 200px);\n    grid-gap: 20px;\n  }\n"])), function (_ref) {
  var theme = _ref.theme;
  return "@media screen and (min-width: ".concat(theme.breakpoints[3], ")");
});
var _default = GridLayout;
exports.default = _default;