import { useQuery } from "@apollo/react-hooks";
import { Flex, Text, Button } from "@theme-ui/components";
import React from "react";
import { useHistory } from "react-router-dom";

import { CLASSES_QUERY, ME_QUERY } from "@escola/graphql";
import { Icon } from "@escola/icons";
import { SpinnerAbsolute, Table } from "@escola/layout";

import { AULA_ROUTES } from "./AulaRoutes";
import { Roles } from "app/layout/Sidebar";
import { useRedirect } from "app/utils";

export const AulaList = () => {
  const { ...me } = useQuery(ME_QUERY);
  const history = useHistory();
  const setRedirect = useRedirect();
  const { data, loading } = useQuery(CLASSES_QUERY);
  const columns = React.useMemo(
    () => [
      {
        Header: null,
        id: "aulas",
        columns: [
          {
            Header: "Número",
            accessor: "number",
          },
          {
            Header: "Disciplina",
            accessor: "subject.name",
            Cell: ({ row }) => row.original.subject.name,
          },
          {
            Header: "Professor",
            accessor: "teacher.name",
            Cell: ({ row }) => row.original.teacher.name,
          },
        ],
      },
    ],
    []
  );

  if (me.loading) return null;

  const role = me.data.me.role.name;

  return (
    <>
      <Flex
        variant="layout.admin.panel"
        sx={{
          justifyContent: "space-between",
          position: "sticky",
          top: 0,
          zIndex: 2,
          mb: 3,
          bg: "white",
          px: 3,
          py: 2,
        }}>
        <Text variant="styles.h2" sx={{ m: 0 }}>
          Aulas
        </Text>
        {(role === Roles.Secretaria || role === Roles.Admin) && (
          <Button
            onClick={() => setRedirect(AULA_ROUTES.add)}
            variant="success">
            <Flex sx={{ alignItems: "center" }}>
              <Icon name="Add" size={15} />
              <Text ml={2}>Adicionar</Text>
            </Flex>
          </Button>
        )}
      </Flex>
      {loading && <SpinnerAbsolute />}
      <Flex sx={{ overflowX: "scroll" }}>
        {data?.classes && (
          <Table.TableUi
            columns={columns}
            data={data.classes}
            clickedRow={true}
            onClick={(row) => history.push(`${AULA_ROUTES.list}/${row.id}`)}
          />
        )}
      </Flex>
    </>
  );
};

export default AulaList;
