import React from "react";
import { Switch, Route } from "react-router-dom";

import { SubjectAdd } from "./SubjectAdd";
import { SubjectList } from "./SubjectList";
import { SubjectView } from "./SubjectView";

export enum SUBJECT_ROUTES {
  list = `/disciplinas`,
  edit = `/disciplinas/:id`,
  add = `/disciplinas/novo`,
}

export default () => (
  <Switch>
    <Route exact path={SUBJECT_ROUTES.list} component={SubjectList} />
    <Route exact path={SUBJECT_ROUTES.add} component={SubjectAdd} />
    <Route exact path={SUBJECT_ROUTES.edit} component={SubjectView} />
  </Switch>
);
