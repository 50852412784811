import { Heading, Text } from "@theme-ui/components";
import React, { useContext, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

import { useAuthentication } from "app/authentication/hooks";
import { SocketConnectionContext } from "app/live/SocketConnection";

export { default as NotificationList } from "./NotificationList";
export { default as NotificationRoutes } from "./NotificationRoutes";

export const Notifications = () => {
  const { isAuthenticated } = useAuthentication();

  return isAuthenticated ? <NotificationsBase /> : null;
};

const NotificationsBase = () => {
  const { ws } = useContext<any>(SocketConnectionContext);
  const notify = ({ subject, content }) =>
    toast.success(
      <>
        <Heading as="h3" variant="styles.h3" sx={{ color: "white", m: 0 }}>
          {subject}
        </Heading>
        <Text>{content}</Text>
      </>,
      {
        position: "top-center",
        autoClose: 20000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  useEffect(() => {
    if (ws) {
      ws.on("notification", (data) => {
        notify(data);
      });
    }

    return () => ws && ws.off("notification");
  }, [ws]);

  return (
    <ToastContainer
      position="top-center"
      autoClose={20000}
      hideProgressBar={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};
