import { useQuery } from "@apollo/react-hooks";
import React from "react";
import { useHistory } from "react-router-dom";
import { Flex, Text, Button } from "@theme-ui/components";

import { Icon } from "@escola/icons";
import { SpinnerAbsolute, Table } from "@escola/layout";

import { useRedirect } from "app/utils";

export const BaseList = ({ query, columns, routes, title, queryItem }) => {
  const history = useHistory();
  const setRedirect = useRedirect();
  const { data, loading } = useQuery(query);

  return (
    <>
      <Flex
        sx={{
          justifyContent: "space-between",
          position: "sticky",
          top: 0,
          zIndex: 2,
          mb: 3,
          bg: "white",
          px: 3,
          py: 2,
        }}>
        <Text variant="styles.h2" sx={{ m: 0 }}>
          {title}
        </Text>
        <Button onClick={() => setRedirect(routes.add)} variant="success">
          <Flex sx={{ alignItems: "center" }}>
            <Icon name="Add" size={15} />
            <Text ml={2}>Adicionar</Text>
          </Flex>
        </Button>
      </Flex>
      {loading && <SpinnerAbsolute />}
      <Flex sx={{ overflowX: "scroll" }}>
        {data?.[queryItem] && (
          <Table.TableUi
            columns={columns}
            data={data[queryItem]}
            clickedRow={true}
            onClick={(row) => history.push(`${routes.list}/${row.id}`)}
          />
        )}
      </Flex>
    </>
  );
};

export default BaseList;
