import React from "react";

import {
  DELETE_SUBJECT_MUTATION,
  UPDATE_SUBJECT_MUTATION,
} from "@escola/graphql";
import { SUBJECT_QUERY } from "@escola/graphql";

import { SubjectDelete } from "./SubjectDelete";
import { SubjectForm } from "./SubjectForm";
import { SUBJECT_ROUTES } from "./SubjectRoutes";
import {
  validationSchema,
  deserializeSubject,
  serializeSubject,
} from "./SubjectUtils";
import { options } from "./SubjectUtils";
import { BaseView } from "app/baseView";

export const SubjectView = (props) => {
  const title = "Editar Disciplina";
  const id = props.match.params.id;
  const query = SUBJECT_QUERY;
  const updateMutation = UPDATE_SUBJECT_MUTATION;
  const deleteMutation = DELETE_SUBJECT_MUTATION;
  const routes = SUBJECT_ROUTES;
  const queryItem = "subjects";
  const deserialize = deserializeSubject;
  const serialize = serializeSubject;
  const updateInput = "updateSubjectInput";
  const deleteInput = "deleteSubjectInput";
  const schema = validationSchema;
  const ItemDelete = SubjectDelete;
  const ItemForm = SubjectForm;
  const mutationOptions = options;

  return (
    <BaseView
      id={id}
      title={title}
      query={query}
      updateMutation={updateMutation}
      deleteMutation={deleteMutation}
      routes={routes}
      queryItem={queryItem}
      deserialize={deserialize}
      serialize={serialize}
      updateInput={updateInput}
      deleteInput={deleteInput}
      validationSchema={schema}
      ItemDelete={ItemDelete}
      ItemForm={ItemForm}
      mutationOptions={mutationOptions}
    />
  );
};

export default SubjectView;
