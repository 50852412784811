import { Box, Grid } from "@theme-ui/components";
import React from "react";

import { SelectField, TextField } from "@escola/fields";
import { SUBJECTS_QUERY, TEACHERS_QUERY } from "@escola/graphql";
import { useQuerySelectOptions } from "@escola/hooks";

import { RichTextField } from "app/fields";
import { streamSources } from "app/live/LiveForm";

export const AulaForm = ({ isDisabled = false }) => {
  const subjects = useQuerySelectOptions(SUBJECTS_QUERY, "subjects");
  const teachers = useQuerySelectOptions(TEACHERS_QUERY, "teachers");

  return (
    <Box>
      <Grid
        sx={{
          gridTemplateColumns: ["1fr", null, null, "1fr 1fr"],
          rowGap: 2,
          columnGap: 4,
          mb: 2,
        }}>
        <Box sx={{ gridColumn: 1 }}>
          <TextField
            label="Número da Aula"
            name="number"
            required
            placeholder="Identificador único para cada aula"
          />
        </Box>
        <Box sx={{ gridColumn: [1, null, null, 2] }}>
          <SelectField label="Disciplina" name="subject" required>
            {subjects.options}
          </SelectField>
        </Box>
      </Grid>

      <SelectField label="Professor" name="teacher" required>
        {teachers.options}
      </SelectField>

      <Grid
        sx={{
          gridTemplateColumns: ["1fr", null, null, "1fr 1fr"],
          rowGap: 2,
          columnGap: 4,
          mb: 2,
        }}>
        <Box sx={{ gridColumn: 1 }}>
          <SelectField
            label="Provedor do Video gravado"
            name="videoSource"
            customEmpty={true}>
            {streamSources.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </SelectField>
        </Box>
        <Box sx={{ gridColumn: [1, null, null, 2] }}>
          <TextField label="Video ID" name="video" />
        </Box>
      </Grid>

      {/* <Box sx={{ bg: "gray.1", px: 3, pt: 2, mb: 4 }}>
        <TextField
          label="Video ID alternativo do youtube para alunos com problemas de conexão (somente em dispositivos móveis)"
          name="alternativeStreaming"
        />
      </Box> */}
      <RichTextField label="Conteúdo da aula" name="content" />
    </Box>
  );
};
