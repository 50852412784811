import React from "react";

import { CREATE_NOTIFICATION_MUTATION } from "@escola/graphql";

import { NotificationForm } from "./NotificationForm";
import { NOTIFICATION_ROUTES } from "./NotificationRoutes";
import { validationSchema, options, initialValues } from "./NotificationUtils";
import { BaseAdd } from "app/baseView";

export const NotificationAdd = () => {
  const title = "Enviar Nova Notificação";
  const createMutation = CREATE_NOTIFICATION_MUTATION;
  const routes = NOTIFICATION_ROUTES;
  const createInput = "createNotification";
  const createItemInput = "createNotificationInput";
  const schema = validationSchema;
  const ItemForm = NotificationForm;
  const itemName = "notification";

  return (
    <BaseAdd
      title={title}
      createMutation={createMutation}
      routes={routes}
      createInput={createInput}
      createItemInput={createItemInput}
      validationSchema={schema}
      ItemForm={ItemForm}
      mutationOptions={options}
      initialValues={initialValues}
      itemName={itemName}
    />
  );
};

export default NotificationAdd;
