import { Box } from "@theme-ui/components";
import React from "react";

export const Card = ({ sx = {}, children }) => (
  <Box
    sx={{
      bg: "white",
      p: 4,
      mb: 3,
      border: "1px solid #DCDCDC",
      borderRadius: 4,
      ...sx,
    }}>
    {children}
  </Box>
);
