import React from "react";
import { Switch, Route } from "react-router-dom";

import { AttendanceList } from "./AttendanceList";

export enum ATTENDANCE_ROUTES {
  list = `/assiduidade`,
}

export default () => (
  <Switch>
    <Route exact path={ATTENDANCE_ROUTES.list} component={AttendanceList} />
  </Switch>
);
