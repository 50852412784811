import { Text, Flex } from "@theme-ui/components";
import React, { useEffect } from "react";
import { Redirect } from "react-router";

import { Icon } from "@escola/icons";

import { AUTHENTICATION_ROUTES } from "./AuthenticationRoutes";
import { useAuthentication } from "./hooks";
import { useApollo } from "app/utils/useApollo";

export const Logout = () => {
  const { logout, isAuthenticated } = useAuthentication();
  const client = useApollo();

  useEffect(() => {
    if (!isAuthenticated) {
      client.resetStore();
      window.location.href = "/";
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    client.resetStore();
    return <Redirect to={AUTHENTICATION_ROUTES.view} />;
  }

  return (
    <Flex
      sx={{
        cursor: "pointer",
        py: 3,
        color: "gray-600",

        ":hover": {
          color: "black",
        },
      }}
      onClick={logout}>
      <Icon name="Logout" size={17} />
      <Text pl={2} sx={{ fontSize: ["20px", null, "16px"] }}>
        Sair
      </Text>
    </Flex>
  );
};
