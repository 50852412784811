import cloneDeep from "lodash.clonedeep";
import { object, string } from "yup";

import { currentConfig } from "@escola/config";
import { file, fileNotRequired } from "@escola/fields";
import { SUBJECTS_QUERY } from "@escola/graphql";

import { FILE_SUPPORTED_FORMATS, FILE_MAX_SIZE } from "./SubjectForm";

const MAX_SIZE = 1024 * 15;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
const uploadPrefix = currentConfig.UPLOAD_PREFIX;

export const validationSchema = object().shape({
  name: string().required("Obrigatório"),
  image: file({ MAX_SIZE, SUPPORTED_FORMATS }),
  file: fileNotRequired({
    MAX_SIZE: FILE_MAX_SIZE,
    SUPPORTED_FORMATS: FILE_SUPPORTED_FORMATS,
  }),
});

export const initialValues: any = {
  name: "",
  image: "",
  file: "",
};

export const options = {
  refetchQueries: [
    {
      query: SUBJECTS_QUERY,
    },
  ],
};

export const deserializeSubject = (data) => {
  const values = cloneDeep(data);

  if (values?.image?.formats?.thumbnail.url) {
    if (!values.image.formats.thumbnail.url.startsWith(uploadPrefix)) {
      values.image.formats.thumbnail.url = `${uploadPrefix}${values.image.formats.thumbnail.url}`;
    }
  }

  if (values?.image?.url) {
    if (!values.image.url.startsWith(uploadPrefix)) {
      values.image.url = `${uploadPrefix}${values.image.url}`;
    }
  }

  if (values?.file?.url) {
    if (!values.file.url.startsWith(uploadPrefix)) {
      values.file.url = `${uploadPrefix}${values.file.url}`;
    }
  }

  return values;
};

export const serializeSubject = (data) => {
  const { __typename, id, ...values } = data;

  if (values.image) {
    values.image = values.image.id;
  }

  if (values.file) {
    values.file = values.file.id;
  }

  return values;
};
