import { Box } from "@theme-ui/components";
import React from "react";

import { TextField } from "@escola/fields";

export const NotificationForm = ({ isDisabled = false }) => {
  return (
    <Box>
      <TextField label="Assunto" name="subject" required />
      <TextField label="Conteúdo" name="content" required />
    </Box>
  );
};
