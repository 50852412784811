import { Alert, Box, Button, Flex, Grid, Text } from "@theme-ui/components";
import { useFormikContext } from "formik";
import React from "react";
import { Styled } from "theme-ui";

import {
  TextField,
  TextAreaField,
  SwitchField,
  SelectField,
} from "@escola/fields";
import { Icon } from "@escola/icons";

import { getFileURL } from "app/utils";

export const payments = [
  {
    id: "tb",
    name: "Transferéncia Bancaria",
  },
  {
    id: "mb",
    name: "MB Way",
  },
  {
    id: "cc",
    name: "Cartão de Credito",
  },
];

export const StudentForm = ({ isDisabled = false }) => {
  const { values } = useFormikContext<any>();

  return (
    <Box>
      {values.confirmed && (
        <Alert variant="info" mb={4}>
          <Flex sx={{ flexDirection: "column" }}>
            <Flex sx={{ color: "success", alignItems: "center" }}>
              <Icon name="Correct" />
              <Text sx={{ ml: 2, fontWeight: "heading", fontSize: 4 }}>
                Inscrição confirmada
              </Text>
            </Flex>
            <Text>Já foi enviado para o e-mail do aluno o link de acesso.</Text>
          </Flex>
        </Alert>
      )}
      <Styled.hr />
      <SwitchField
        label="Aluno Ativo"
        name="active"
        required
        disabled={isDisabled}
        sx={{ justifyContent: "left", mb: 3 }}
      />

      <Grid
        sx={{
          gridTemplateColumns: ["1fr", null, null, "1fr 1fr"],
          rowGap: 2,
          columnGap: 4,
          mb: 2,
        }}>
        <Box sx={{ gridColumn: 1 }}>
          <TextField label="Nome" name="name" required />
        </Box>
        <Box sx={{ gridColumn: [1, null, null, 2] }}>
          <TextField label="E-mail" name="email" required />
        </Box>
      </Grid>

      <Grid
        sx={{
          gridTemplateColumns: ["1fr", null, null, "1fr 1fr"],
          rowGap: 2,
          columnGap: 4,
          mb: 2,
        }}>
        <Box sx={{ gridColumn: 1 }}>
          <TextField label="Telemóvel" name="phone" />
        </Box>
        <Box sx={{ gridColumn: [1, null, null, 2] }}>
          <TextField label="Igreja" name="church" />
        </Box>
      </Grid>

      <Grid
        sx={{
          gridTemplateColumns: ["1fr", null, null, "1fr 1fr"],
          rowGap: 2,
          columnGap: 4,
          mb: 2,
        }}>
        <Box sx={{ gridColumn: 1 }}>
          <SelectField label="Método de Pagamento" name="payment">
            {payments.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </SelectField>
        </Box>
        <Flex sx={{ gridColumn: [1, null, null, 2], alignItems: "center" }}>
          {values?.file?.url && (
            <a
              href={`${getFileURL(values.file.url)}`}
              target="_blank"
              rel="noopener noreferrer"
              download>
              <Button type="button" variant="large">
                Abrir comprovante de pagamento
              </Button>
            </a>
          )}
          {values?.paymentId && (
            <Text>
              <b>paymentID</b>: {values?.paymentId}
            </Text>
          )}
        </Flex>
      </Grid>

      <TextAreaField label="Obs" name="obs" />
    </Box>
  );
};
