import React from "react";

import { CREATE_SUBJECT_MUTATION } from "@escola/graphql";

import { SubjectForm } from "./SubjectForm";
import { SUBJECT_ROUTES } from "./SubjectRoutes";
import {
  validationSchema,
  options,
  initialValues,
  serializeSubject,
} from "./SubjectUtils";
import { BaseAdd } from "app/baseView";

export const SubjectAdd = () => {
  const title = "Nova Disciplina";
  const createMutation = CREATE_SUBJECT_MUTATION;
  const routes = SUBJECT_ROUTES;
  const createInput = "createSubject";
  const createItemInput = "createSubjectInput";
  const serialize = serializeSubject;
  const schema = validationSchema;
  const ItemForm = SubjectForm;
  const itemName = "subject";

  return (
    <BaseAdd
      title={title}
      createMutation={createMutation}
      routes={routes}
      createInput={createInput}
      createItemInput={createItemInput}
      validationSchema={schema}
      ItemForm={ItemForm}
      mutationOptions={options}
      initialValues={initialValues}
      itemName={itemName}
      serialize={serialize}
    />
  );
};

export default SubjectAdd;
