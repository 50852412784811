import { Box } from "@theme-ui/components";
import React from "react";

import { SelectField, TextField } from "@escola/fields";

import { streamSources } from "app/live/LiveForm";

export const AulaFormMidia = ({ isDisabled = false }) => {
  return (
    <Box>
      <SelectField
        label="Provedor do Video gravado"
        name="videoSource"
        customEmpty={true}>
        {streamSources.map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </SelectField>
      <TextField label="Video ID" name="video" />
    </Box>
  );
};
