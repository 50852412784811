import cloneDeep from "lodash.clonedeep";
import { object, string } from "yup";

import { STUDENTS_QUERY } from "@escola/graphql";

export const validationSchema = object().shape({
  name: string().required("Obrigatório"),
  email: string().required("Obrigatório"),
});

export const initialValues: any = {
  name: "",
  email: "",
};

export const options = {
  refetchQueries: [
    {
      query: STUDENTS_QUERY,
    },
  ],
};

export const deserializeStudent = (data) => {
  const values = cloneDeep(data);
  values.active = !values.blocked;
  return values;
};

export const serializeStudent = (data) => {
  const { __typename, id, file, active, ...values } = data;
  values.blocked = !active;
  return values;
};

export const serializeUpdateStudent = (data) => {
  const { __typename, id, file, active, ...values } = data;
  values.blocked = !active;

  return values;
};
