"use strict";

exports.__esModule = true;
exports.default = void 0;

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _css = _interopRequireDefault(require("@styled-system/css"));

var _Area = _interopRequireDefault(require("./Area"));

var _templateObject;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var EmptyLayout = (0, _styled.default)(_Area.default)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  height: 100vh;\n  grid-template-columns: 1fr;\n  grid-template-rows: auto 1fr;\n  grid-template-areas:\n    \"navbar\"\n    \"content\";\n\n  ", "\n"])), function (_ref) {
  var sx = _ref.sx;
  return (0, _css.default)(_objectSpread({}, sx));
});
var _default = EmptyLayout;
exports.default = _default;