import { useQuery } from "@apollo/react-hooks";
import { keyframes } from "@emotion/core";
import styled from "@emotion/styled";
import css from "@styled-system/css";
import React, { useContext, useEffect } from "react";
import { Box, Badge, Flex, Text } from "@theme-ui/components";

import { LIVE_QUERY } from "@escola/graphql";
import { Icon } from "@escola/icons";

import { SocketConnectionContext } from "./SocketConnection";

export { default as LiveRoutes } from "./LiveRoutes";

const fade = keyframes`
  0%,100% { opacity: 0 }
  50% { opacity: 1 }
`;

export const LiveButton = styled(Box)`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  animation: ${fade} 1s ease-out infinite;

  ${({ sx = {} }) =>
    css({
      bg: "danger",
      mr: 2,
      ...sx,
    })}
`;

const LiveBadge = (props) => (
  <Badge
    sx={{ bg: "success", py: 1, px: 2, textDecoration: "none" }}
    {...props}>
    <Flex sx={{ alignItems: "center" }}>
      <LiveButton />
      <Text sx={{ mr: 2, fontSize: 2 }}>Transmissão Online</Text>
      <Icon name="VideoCamera" />
    </Flex>
  </Badge>
);

export const LiveStatus = () => {
  const { data, loading, refetch } = useQuery(LIVE_QUERY);
  const { ws } = useContext<any>(SocketConnectionContext);

  useEffect(() => {
    if (ws) {
      ws.on("live:active", () => {
        try {
          refetch();
        } catch (e) {
          console.log(e);
        }
      });
    }
  }, [ws]);

  if (loading) return null;

  return data?.live?.active ? <LiveBadge /> : null;
};
