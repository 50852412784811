import { useMutation } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { Box, Flex, Text, Button, Label } from "@theme-ui/components";
import { Formik, Form } from "formik";
import React, { useEffect } from "react";

import { CREATE_CLASS_MUTATION } from "@escola/graphql";
import { Icon } from "@escola/icons";
import { SpinnerAbsolute } from "@escola/layout";

import { AulaForm } from "./AulaForm";
import { AULA_ROUTES } from "./AulaRoutes";
import { validationSchema, options, initialValues } from "./AulaUtils";
import { Card } from "app/layout/Card";
import { useRedirect, useAlertSuccess } from "app/utils";

export const AulaAdd = (props) => {
  const setRedirect = useRedirect();
  const [createClass, { loading, data, error }] = useMutation(
    CREATE_CLASS_MUTATION,
    options
  );
  const alertSuccess = useAlertSuccess();

  const createClassHandler = (data) => {
    createClass({
      variables: { createClassInput: { data } },
    }).catch((e) => {
      Sentry.withScope(function (scope) {
        scope.setLevel(Sentry.Severity.Error);
        Sentry.captureException(e);
      });
    });
  };

  useEffect(() => {
    if (data?.createClass.class.id) {
      setRedirect(AULA_ROUTES.list);
      alertSuccess("Aula adicionada com sucesso");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Card
      sx={{
        justifyContent: "space-between",
        position: "sticky",
        top: 0,
        zIndex: 2,
      }}>
      <Text variant="styles.h2" sx={{ mb: 3 }}>
        Nova Aula
      </Text>

      <Formik
        validateOnMount={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          createClassHandler(values);
        }}>
        {({ isValid }) => {
          return (
            <Form autoComplete="off" noValidate={true}>
              <fieldset
                disabled={loading}
                style={{ border: "none", padding: 0 }}>
                <AulaForm />
                <Box
                  sx={{
                    position: "fixed",
                    bottom: "0",
                    left: [0, null, "200px"],
                    right: 0,
                  }}>
                  <Card
                    sx={{
                      display: "flex",
                      mb: 0,
                      bg: "white",
                      width: "100%",
                      py: 0,
                      px: 4,
                      justifyContent: "flex-end",
                    }}>
                    {loading && <SpinnerAbsolute />}
                    <Flex
                      sx={{
                        mb: 0,
                        bg: "white",
                        width: "100%",
                        p: 3,
                        justifyContent: "space-between",
                      }}>
                      <Box>
                        {error && (
                          <Label
                            variant="label.error"
                            sx={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}>
                            Erro ao guardar, tente novamente...
                          </Label>
                        )}
                      </Box>
                      <Box>
                        <Button
                          variant="outline"
                          mr={2}
                          onClick={() => setRedirect(AULA_ROUTES.list)}>
                          <Flex sx={{ alignItems: "center" }}>
                            <Icon name="Close" size={15} color="danger" />
                            <Text ml={2}>Cancelar</Text>
                          </Flex>
                        </Button>
                        <Button
                          type="submit"
                          variant="primary"
                          disabled={!isValid || loading}>
                          Guardar
                        </Button>
                      </Box>
                    </Flex>
                  </Card>
                </Box>
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};

export default AulaAdd;
