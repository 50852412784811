import React, { Dispatch, SetStateAction } from "react";

export type AppContextProps = {
  sideBarOpen: boolean;
  setSideBarOpen: Dispatch<SetStateAction<boolean>>;
};

export const AppContext = React.createContext<AppContextProps>({
  sideBarOpen: false,
  setSideBarOpen: (open) => open,
});
