import React from "react";
import { Switch, Route } from "react-router-dom";

import { NotificationAdd } from "./NotificationAdd";
import { NotificationList } from "./NotificationList";
import { NotificationView } from "./NotificationView";

export enum NOTIFICATION_ROUTES {
  list = `/notifications`,
  edit = `/notifications/:id`,
  add = `/notifications/novo`,
}

export default () => (
  <Switch>
    <Route exact path={NOTIFICATION_ROUTES.list} component={NotificationList} />
    <Route exact path={NOTIFICATION_ROUTES.add} component={NotificationAdd} />
    <Route exact path={NOTIFICATION_ROUTES.edit} component={NotificationView} />
  </Switch>
);
