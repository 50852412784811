import { Text } from "@theme-ui/components";
import React from "react";

import { NOTIFICATIONS_QUERY } from "@escola/graphql";
import { useFormater } from "@escola/utils";

import { NOTIFICATION_ROUTES } from "./NotificationRoutes";
import { BaseList } from "app/baseView";

export const NotificationList = () => {
  const title = "Notificações Enviadas";
  const query = NOTIFICATIONS_QUERY;
  const routes = NOTIFICATION_ROUTES;
  const columns = React.useMemo(
    () => [
      {
        Header: null,
        id: "notifications",
        columns: [
          {
            Header: "Assunto",
            accessor: "subject",
            Cell: ({ row }) => row.original.subject,
          },
          {
            Header: "Enviada em",
            accessor: "created_at",
            Cell: ({ row }) => {
              return (
                <Text sx={{ textTransform: "capitalize" }}>
                  {useFormater({
                    value: row.original.created_at,
                    format: "EEEE, dd 'de' MMMM - HH:mm'h'",
                  })}
                </Text>
              );
            },
          },
        ],
      },
    ],
    []
  );

  return (
    <BaseList
      query={query}
      columns={columns}
      routes={routes}
      title={title}
      queryItem="notifications"
    />
  );
};

export default NotificationList;
