import React from "react";

import {
  DELETE_NOTIFICATION_MUTATION,
  UPDATE_NOTIFICATION_MUTATION,
  NOTIFICATION_QUERY,
} from "@escola/graphql";

import { NotificationDelete } from "./NotificationDelete";
import { NotificationForm } from "./NotificationForm";
import { NOTIFICATION_ROUTES } from "./NotificationRoutes";
import {
  validationSchema,
  deserializeNotification,
  serializeNotification,
} from "./NotificationUtils";
import { options } from "./NotificationUtils";
import { BaseView } from "app/baseView";

export const NotificationView = (props) => {
  const title = "Editar Notificação";
  const id = props.match.params.id;
  const query = NOTIFICATION_QUERY;
  const updateMutation = UPDATE_NOTIFICATION_MUTATION;
  const deleteMutation = DELETE_NOTIFICATION_MUTATION;
  const routes = NOTIFICATION_ROUTES;
  const queryItem = "notifications";
  const deserialize = deserializeNotification;
  const serialize = serializeNotification;
  const updateInput = "updateNotificationInput";
  const deleteInput = "deleteNotificationInput";
  const schema = validationSchema;
  const ItemDelete = NotificationDelete;
  const ItemForm = NotificationForm;
  const mutationOptions = options;

  return (
    <BaseView
      id={id}
      title={title}
      query={query}
      updateMutation={updateMutation}
      deleteMutation={deleteMutation}
      routes={routes}
      queryItem={queryItem}
      deserialize={deserialize}
      serialize={serialize}
      updateInput={updateInput}
      deleteInput={deleteInput}
      validationSchema={schema}
      ItemDelete={ItemDelete}
      ItemForm={ItemForm}
      mutationOptions={mutationOptions}
    />
  );
};

export default NotificationView;
