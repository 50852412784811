import React from "react";

import { SUBJECTS_QUERY } from "@escola/graphql";

import { SUBJECT_ROUTES } from "./SubjectRoutes";
import { BaseList } from "app/baseView";

export const SubjectList = () => {
  const title = "Disciplinas";
  const query = SUBJECTS_QUERY;
  const routes = SUBJECT_ROUTES;
  const columns = React.useMemo(
    () => [
      {
        Header: null,
        id: "subjects",
        columns: [
          {
            Header: "Nome",
            accessor: "name",
            Cell: ({ row }) => row.original.name,
          },
        ],
      },
    ],
    []
  );

  return (
    <BaseList
      query={query}
      columns={columns}
      routes={routes}
      title={title}
      queryItem="subjects"
    />
  );
};

export default SubjectList;
