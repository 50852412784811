"use strict";

exports.__esModule = true;
exports.DELETE_NOTIFICATION_MUTATION = exports.UPDATE_NOTIFICATION_MUTATION = exports.CREATE_NOTIFICATION_MUTATION = exports.NOTIFICATION_QUERY = exports.NOTIFICATIONS_QUERY = exports.GET_MY_ATTENDANCE_SUMMARY = exports.GET_ATTENDANCE_SUMMARY = exports.GET_ATTENDANCE_SUMMARY_BY_USER = exports.GET_ATTENDANCE_BY_USER = exports.GET_ATTENDANCE_BY_CLASS = exports.UPDATE_IS_OPEN_MUTATION = exports.IS_OPEN = exports.RESET_PASSWORD_MUTATION = exports.FORGOT_PASSWORD = exports.VALIDATE_TOKEN = exports.CONFIRM_USER = exports.DELETE_STUDENT_MUTATION = exports.UPDATE_STUDENT_MUTATION = exports.CREATE_STUDENT_MUTATION = exports.USERS_QUERY = exports.STUDENTS_QUERY = exports.STUDENT_QUERY = exports.DELETE_SCHEDULER_MUTATION = exports.UPDATE_SCHEDULER_MUTATION = exports.CREATE_SCHEDULER_MUTATION = exports.SCHEDULER_QUERY = exports.SCHEDULERS_QUERY = exports.DELETE_TEACHER_MUTATION = exports.UPDATE_TEACHER_MUTATION = exports.CREATE_TEACHER_MUTATION = exports.DELETE_SUBJECT_MUTATION = exports.UPDATE_SUBJECT_MUTATION = exports.CREATE_SUBJECT_MUTATION = exports.SUBJECT_QUERY = exports.SUBJECTS_QUERY = exports.DELETE_CLASS_MUTATION = exports.UPDATE_CLASS_MUTATION = exports.CREATE_CLASS_MUTATION = exports.USER_QUERY = exports.ME_QUERY = exports.UPDATE_LIVE_MUTATION = exports.LIVE_QUERY = exports.TEACHER_QUERY = exports.TEACHERS_QUERY = exports.CLASS_QUERY = exports.CLASSES_QUERY = void 0;

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19, _templateObject20, _templateObject21, _templateObject22, _templateObject23, _templateObject24, _templateObject25, _templateObject26, _templateObject27, _templateObject28, _templateObject29, _templateObject30, _templateObject31, _templateObject32, _templateObject33, _templateObject34, _templateObject35, _templateObject36, _templateObject37, _templateObject38, _templateObject39, _templateObject40, _templateObject41, _templateObject42, _templateObject43, _templateObject44, _templateObject45, _templateObject46;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var CLASSES_QUERY = (0, _graphqlTag.default)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  {\n    classes(sort: \"id:asc\") {\n      id\n      number\n      subject {\n        id\n        name\n        image {\n          id\n          url\n          mime\n          name\n          size\n          formats\n        }\n      }\n      teacher {\n        id\n        name\n      }\n    }\n  }\n"])));
exports.CLASSES_QUERY = CLASSES_QUERY;
var CLASS_QUERY = (0, _graphqlTag.default)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  query classes($where: JSON!) {\n    classes(where: $where, sort: \"id:asc\") {\n      id\n      number\n      content\n      videoSource\n      video\n      alternativeStreaming\n      subject {\n        id\n        name\n        image {\n          id\n          url\n          mime\n          name\n          size\n          formats\n        }\n        file {\n          id\n          url\n          mime\n          name\n          size\n        }\n      }\n      teacher {\n        id\n        name\n        picture {\n          url\n        }\n      }\n    }\n  }\n"])));
exports.CLASS_QUERY = CLASS_QUERY;
var TEACHERS_QUERY = (0, _graphqlTag.default)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  {\n    teachers(sort: \"id:asc\") {\n      id\n      name\n      slug\n    }\n  }\n"])));
exports.TEACHERS_QUERY = TEACHERS_QUERY;
var TEACHER_QUERY = (0, _graphqlTag.default)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  query teachers($where: JSON!) {\n    teachers(where: $where) {\n      id\n      name\n      slug\n      picture {\n        id\n        url\n        mime\n        name\n        size\n        formats\n      }\n    }\n  }\n"])));
exports.TEACHER_QUERY = TEACHER_QUERY;
var LIVE_QUERY = (0, _graphqlTag.default)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  {\n    live {\n      id\n      streamId\n      alternativeStreaming\n      active\n      streamSource\n      text\n      scheduler {\n        id\n        name\n        dateStart\n        dateEnd\n        class {\n          id\n          number\n          subject {\n            id\n            name\n            file {\n              id\n              url\n              mime\n              name\n              size\n            }\n          }\n          teacher {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n"])));
exports.LIVE_QUERY = LIVE_QUERY;
var UPDATE_LIVE_MUTATION = (0, _graphqlTag.default)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  mutation updateLive($live: updateLiveInput) {\n    updateLive(input: $live) {\n      live {\n        id\n        streamId\n        alternativeStreaming\n        active\n        streamSource\n        scheduler {\n          name\n          class {\n            number\n            subject {\n              name\n            }\n            teacher {\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n"])));
exports.UPDATE_LIVE_MUTATION = UPDATE_LIVE_MUTATION;
var ME_QUERY = (0, _graphqlTag.default)(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  {\n    me {\n      id\n      name\n      staff\n      alternativeStreaming\n      role {\n        name\n      }\n    }\n  }\n"])));
exports.ME_QUERY = ME_QUERY;
var USER_QUERY = (0, _graphqlTag.default)(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  {\n    userAccount {\n      id\n    }\n  }\n"])));
exports.USER_QUERY = USER_QUERY;
var CREATE_CLASS_MUTATION = (0, _graphqlTag.default)(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  mutation createClass($createClassInput: createClassInput) {\n    createClass(input: $createClassInput) {\n      class {\n        id\n      }\n    }\n  }\n"])));
exports.CREATE_CLASS_MUTATION = CREATE_CLASS_MUTATION;
var UPDATE_CLASS_MUTATION = (0, _graphqlTag.default)(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  mutation updateClass($updateClassInput: updateClassInput) {\n    updateClass(input: $updateClassInput) {\n      class {\n        id\n      }\n    }\n  }\n"])));
exports.UPDATE_CLASS_MUTATION = UPDATE_CLASS_MUTATION;
var DELETE_CLASS_MUTATION = (0, _graphqlTag.default)(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  mutation deleteClass($deleteClassInput: deleteClassInput) {\n    deleteClass(input: $deleteClassInput) {\n      class {\n        id\n      }\n    }\n  }\n"])));
exports.DELETE_CLASS_MUTATION = DELETE_CLASS_MUTATION;
var SUBJECTS_QUERY = (0, _graphqlTag.default)(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  {\n    subjects(sort: \"id:asc\") {\n      id\n      name\n      image {\n        id\n        url\n        mime\n        name\n        size\n        formats\n      }\n      file {\n        id\n        url\n        mime\n        name\n        size\n      }\n    }\n  }\n"])));
exports.SUBJECTS_QUERY = SUBJECTS_QUERY;
var SUBJECT_QUERY = (0, _graphqlTag.default)(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  query subjects($where: JSON!) {\n    subjects(where: $where) {\n      id\n      name\n      image {\n        id\n        url\n        mime\n        name\n        size\n        formats\n      }\n      file {\n        id\n        url\n        mime\n        name\n        size\n      }\n    }\n  }\n"])));
exports.SUBJECT_QUERY = SUBJECT_QUERY;
var CREATE_SUBJECT_MUTATION = (0, _graphqlTag.default)(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  mutation createSubject($createSubjectInput: createSubjectInput) {\n    createSubject(input: $createSubjectInput) {\n      subject {\n        id\n      }\n    }\n  }\n"])));
exports.CREATE_SUBJECT_MUTATION = CREATE_SUBJECT_MUTATION;
var UPDATE_SUBJECT_MUTATION = (0, _graphqlTag.default)(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  mutation updateSubject($updateSubjectInput: updateSubjectInput) {\n    updateSubject(input: $updateSubjectInput) {\n      subject {\n        id\n      }\n    }\n  }\n"])));
exports.UPDATE_SUBJECT_MUTATION = UPDATE_SUBJECT_MUTATION;
var DELETE_SUBJECT_MUTATION = (0, _graphqlTag.default)(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n  mutation deleteSubject($deleteSubjectInput: deleteSubjectInput) {\n    deleteSubject(input: $deleteSubjectInput) {\n      subject {\n        id\n      }\n    }\n  }\n"])));
exports.DELETE_SUBJECT_MUTATION = DELETE_SUBJECT_MUTATION;
var CREATE_TEACHER_MUTATION = (0, _graphqlTag.default)(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n  mutation createTeacher($createTeacherInput: createTeacherInput) {\n    createTeacher(input: $createTeacherInput) {\n      teacher {\n        id\n      }\n    }\n  }\n"])));
exports.CREATE_TEACHER_MUTATION = CREATE_TEACHER_MUTATION;
var UPDATE_TEACHER_MUTATION = (0, _graphqlTag.default)(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["\n  mutation updateTeacher($updateTeacherInput: updateTeacherInput) {\n    updateTeacher(input: $updateTeacherInput) {\n      teacher {\n        id\n      }\n    }\n  }\n"])));
exports.UPDATE_TEACHER_MUTATION = UPDATE_TEACHER_MUTATION;
var DELETE_TEACHER_MUTATION = (0, _graphqlTag.default)(_templateObject19 || (_templateObject19 = _taggedTemplateLiteral(["\n  mutation deleteTeacher($deleteTeacherInput: deleteTeacherInput) {\n    deleteTeacher(input: $deleteTeacherInput) {\n      teacher {\n        id\n      }\n    }\n  }\n"])));
exports.DELETE_TEACHER_MUTATION = DELETE_TEACHER_MUTATION;
var SCHEDULERS_QUERY = (0, _graphqlTag.default)(_templateObject20 || (_templateObject20 = _taggedTemplateLiteral(["\n  {\n    schedulers(sort: \"dateStart:asc\") {\n      id\n      dateStart\n      dateEnd\n      name\n      class {\n        id\n        number\n        subject {\n          id\n          name\n        }\n        teacher {\n          id\n          name\n          picture {\n            url\n          }\n        }\n      }\n    }\n  }\n"])));
exports.SCHEDULERS_QUERY = SCHEDULERS_QUERY;
var SCHEDULER_QUERY = (0, _graphqlTag.default)(_templateObject21 || (_templateObject21 = _taggedTemplateLiteral(["\n  query users($where: JSON!) {\n    schedulers(where: $where, sort: \"dateStart:asc\") {\n      id\n      dateStart\n      dateEnd\n      name\n      class {\n        id\n        number\n        subject {\n          id\n          name\n        }\n        teacher {\n          id\n          name\n          picture {\n            url\n          }\n        }\n      }\n    }\n  }\n"])));
exports.SCHEDULER_QUERY = SCHEDULER_QUERY;
var CREATE_SCHEDULER_MUTATION = (0, _graphqlTag.default)(_templateObject22 || (_templateObject22 = _taggedTemplateLiteral(["\n  mutation createScheduler($createSchedulerInput: createSchedulerInput) {\n    createScheduler(input: $createSchedulerInput) {\n      scheduler {\n        id\n      }\n    }\n  }\n"])));
exports.CREATE_SCHEDULER_MUTATION = CREATE_SCHEDULER_MUTATION;
var UPDATE_SCHEDULER_MUTATION = (0, _graphqlTag.default)(_templateObject23 || (_templateObject23 = _taggedTemplateLiteral(["\n  mutation updateScheduler($updateSchedulerInput: updateSchedulerInput) {\n    updateScheduler(input: $updateSchedulerInput) {\n      scheduler {\n        id\n      }\n    }\n  }\n"])));
exports.UPDATE_SCHEDULER_MUTATION = UPDATE_SCHEDULER_MUTATION;
var DELETE_SCHEDULER_MUTATION = (0, _graphqlTag.default)(_templateObject24 || (_templateObject24 = _taggedTemplateLiteral(["\n  mutation deleteScheduler($deleteSchedulerInput: deleteSchedulerInput) {\n    deleteScheduler(input: $deleteSchedulerInput) {\n      scheduler {\n        id\n      }\n    }\n  }\n"])));
exports.DELETE_SCHEDULER_MUTATION = DELETE_SCHEDULER_MUTATION;
var STUDENT_QUERY = (0, _graphqlTag.default)(_templateObject25 || (_templateObject25 = _taggedTemplateLiteral(["\n  query users($where: JSON!) {\n    users(where: $where) {\n      id\n      name\n      email\n      blocked\n      confirmed\n      church\n      phone\n      obs\n      payment\n      paymentId\n      file {\n        id\n        url\n        mime\n        name\n        size\n        formats\n      }\n      alternativeStreaming\n    }\n  }\n"])));
exports.STUDENT_QUERY = STUDENT_QUERY;
var STUDENTS_QUERY = (0, _graphqlTag.default)(_templateObject26 || (_templateObject26 = _taggedTemplateLiteral(["\n  {\n    users(where: { role: { name: \"Alunos\" } }, sort: \"name:asc\") {\n      id\n      name\n      email\n      blocked\n      confirmed\n      church\n      alternativeStreaming\n    }\n  }\n"])));
exports.STUDENTS_QUERY = STUDENTS_QUERY;
var USERS_QUERY = (0, _graphqlTag.default)(_templateObject27 || (_templateObject27 = _taggedTemplateLiteral(["\n  {\n    users(sort: \"name:asc\") {\n      id\n      name\n      email\n      blocked\n      confirmed\n      role {\n        name\n      }\n    }\n  }\n"])));
exports.USERS_QUERY = USERS_QUERY;
var CREATE_STUDENT_MUTATION = (0, _graphqlTag.default)(_templateObject28 || (_templateObject28 = _taggedTemplateLiteral(["\n  mutation registerUser($registerUserInput: UsersRegisterInput!) {\n    registerUser(input: $registerUserInput) {\n      user {\n        id\n      }\n    }\n  }\n"])));
exports.CREATE_STUDENT_MUTATION = CREATE_STUDENT_MUTATION;
var UPDATE_STUDENT_MUTATION = (0, _graphqlTag.default)(_templateObject29 || (_templateObject29 = _taggedTemplateLiteral(["\n  mutation updateUser($updateUserInput: updateUserInput) {\n    updateUser(input: $updateUserInput) {\n      user {\n        id\n      }\n    }\n  }\n"])));
exports.UPDATE_STUDENT_MUTATION = UPDATE_STUDENT_MUTATION;
var DELETE_STUDENT_MUTATION = (0, _graphqlTag.default)(_templateObject30 || (_templateObject30 = _taggedTemplateLiteral(["\n  mutation deleteUser($deleteUserInput: deleteUserInput) {\n    deleteUser(input: $deleteUserInput) {\n      user {\n        id\n      }\n    }\n  }\n"])));
exports.DELETE_STUDENT_MUTATION = DELETE_STUDENT_MUTATION;
var CONFIRM_USER = (0, _graphqlTag.default)(_templateObject31 || (_templateObject31 = _taggedTemplateLiteral(["\n  mutation($input: ConfirmUserInput!) {\n    confirmUser(input: $input) {\n      id\n    }\n  }\n"])));
exports.CONFIRM_USER = CONFIRM_USER;
var VALIDATE_TOKEN = (0, _graphqlTag.default)(_templateObject32 || (_templateObject32 = _taggedTemplateLiteral(["\n  query validateToken($input: TokenInput!) {\n    validateToken(input: $input) {\n      id\n      email\n    }\n  }\n"])));
exports.VALIDATE_TOKEN = VALIDATE_TOKEN;
var FORGOT_PASSWORD = (0, _graphqlTag.default)(_templateObject33 || (_templateObject33 = _taggedTemplateLiteral(["\n  mutation forgotPassword($email: String!) {\n    forgotPassword(email: $email) {\n      ok\n    }\n  }\n"])));
exports.FORGOT_PASSWORD = FORGOT_PASSWORD;
var RESET_PASSWORD_MUTATION = (0, _graphqlTag.default)(_templateObject34 || (_templateObject34 = _taggedTemplateLiteral(["\n  mutation resetPassword(\n    $password: String!\n    $passwordConfirmation: String!\n    $code: String!\n  ) {\n    resetPassword(\n      password: $password\n      passwordConfirmation: $passwordConfirmation\n      code: $code\n    ) {\n      user {\n        id\n      }\n    }\n  }\n"])));
exports.RESET_PASSWORD_MUTATION = RESET_PASSWORD_MUTATION;
var IS_OPEN = (0, _graphqlTag.default)(_templateObject35 || (_templateObject35 = _taggedTemplateLiteral(["\n  {\n    isOpen {\n      id\n      open\n      text\n      certificates\n    }\n  }\n"])));
exports.IS_OPEN = IS_OPEN;
var UPDATE_IS_OPEN_MUTATION = (0, _graphqlTag.default)(_templateObject36 || (_templateObject36 = _taggedTemplateLiteral(["\n  mutation updateIsOpen($isOpen: updateIsOpenInput) {\n    updateIsOpen(input: $isOpen) {\n      isOpen {\n        id\n        open\n        text\n      }\n    }\n  }\n"])));
exports.UPDATE_IS_OPEN_MUTATION = UPDATE_IS_OPEN_MUTATION;
var GET_ATTENDANCE_BY_CLASS = (0, _graphqlTag.default)(_templateObject37 || (_templateObject37 = _taggedTemplateLiteral(["\n  query getAttendanceByClass($input: AttendanceByClassInput!) {\n    getAttendanceByClass(input: $input) {\n      total\n    }\n  }\n"])));
exports.GET_ATTENDANCE_BY_CLASS = GET_ATTENDANCE_BY_CLASS;
var GET_ATTENDANCE_BY_USER = (0, _graphqlTag.default)(_templateObject38 || (_templateObject38 = _taggedTemplateLiteral(["\n  query getAttendanceByUser($input: AttendanceByUserInput!) {\n    getAttendanceByUser(input: $input) {\n      data {\n        class\n        total\n      }\n    }\n  }\n"])));
exports.GET_ATTENDANCE_BY_USER = GET_ATTENDANCE_BY_USER;
var GET_ATTENDANCE_SUMMARY_BY_USER = (0, _graphqlTag.default)(_templateObject39 || (_templateObject39 = _taggedTemplateLiteral(["\n  query getAttendanceSummaryByUser($input: AttendanceSummaryByUserInput!) {\n    getAttendanceSummaryByUser(input: $input) {\n      data {\n        class\n        total\n      }\n    }\n  }\n"])));
exports.GET_ATTENDANCE_SUMMARY_BY_USER = GET_ATTENDANCE_SUMMARY_BY_USER;
var GET_ATTENDANCE_SUMMARY = (0, _graphqlTag.default)(_templateObject40 || (_templateObject40 = _taggedTemplateLiteral(["\n  {\n    getAttendanceSummary {\n      data\n    }\n  }\n"])));
exports.GET_ATTENDANCE_SUMMARY = GET_ATTENDANCE_SUMMARY;
var GET_MY_ATTENDANCE_SUMMARY = (0, _graphqlTag.default)(_templateObject41 || (_templateObject41 = _taggedTemplateLiteral(["\n  {\n    getMyAttendanceSummary {\n      data\n    }\n  }\n"])));
exports.GET_MY_ATTENDANCE_SUMMARY = GET_MY_ATTENDANCE_SUMMARY;
var NOTIFICATIONS_QUERY = (0, _graphqlTag.default)(_templateObject42 || (_templateObject42 = _taggedTemplateLiteral(["\n  {\n    notifications(sort: \"id:desc\") {\n      id\n      subject\n      content\n      created_at\n    }\n  }\n"])));
exports.NOTIFICATIONS_QUERY = NOTIFICATIONS_QUERY;
var NOTIFICATION_QUERY = (0, _graphqlTag.default)(_templateObject43 || (_templateObject43 = _taggedTemplateLiteral(["\n  query notifications($where: JSON!) {\n    notifications(where: $where) {\n      id\n      subject\n      content\n      created_at\n    }\n  }\n"])));
exports.NOTIFICATION_QUERY = NOTIFICATION_QUERY;
var CREATE_NOTIFICATION_MUTATION = (0, _graphqlTag.default)(_templateObject44 || (_templateObject44 = _taggedTemplateLiteral(["\n  mutation createNotification(\n    $createNotificationInput: createNotificationInput\n  ) {\n    createNotification(input: $createNotificationInput) {\n      notification {\n        id\n      }\n    }\n  }\n"])));
exports.CREATE_NOTIFICATION_MUTATION = CREATE_NOTIFICATION_MUTATION;
var UPDATE_NOTIFICATION_MUTATION = (0, _graphqlTag.default)(_templateObject45 || (_templateObject45 = _taggedTemplateLiteral(["\n  mutation updateNotification(\n    $updateNotificationInput: updateNotificationInput\n  ) {\n    updateNotification(input: $updateNotificationInput) {\n      notification {\n        id\n      }\n    }\n  }\n"])));
exports.UPDATE_NOTIFICATION_MUTATION = UPDATE_NOTIFICATION_MUTATION;
var DELETE_NOTIFICATION_MUTATION = (0, _graphqlTag.default)(_templateObject46 || (_templateObject46 = _taggedTemplateLiteral(["\n  mutation deleteNotification(\n    $deleteNotificationInput: deleteNotificationInput\n  ) {\n    deleteNotification(input: $deleteNotificationInput) {\n      notification {\n        id\n      }\n    }\n  }\n"])));
exports.DELETE_NOTIFICATION_MUTATION = DELETE_NOTIFICATION_MUTATION;