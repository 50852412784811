import { useMutation } from "@apollo/react-hooks";
import { useDisclosure } from "@chakra-ui/core";
import { Button } from "@theme-ui/components";
import React, { useEffect } from "react";

import { options } from "./NotificationUtils";
import { DeleteModal } from "app/modal";
import { useAlertSuccess } from "app/utils";

export const NotificationDelete = ({
  variables,
  mutation,
  sx,
  onDelete,
  disabled,
  children,
}) => {
  const [deleteMutation, { data, loading }] = useMutation(mutation, options);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const alertSuccess = useAlertSuccess();

  const submitDelete = () => {
    deleteMutation({ variables });
  };

  useEffect(() => {
    if (data?.deleteNotification && onDelete) {
      onDelete();
      alertSuccess("Item removido com sucesso.");
    }
  }, [data]);

  const onConfirm = () => {
    submitDelete();
  };

  return (
    <>
      <Button
        type="button"
        variant="danger"
        sx={sx}
        onClick={onOpen}
        disabled={loading || disabled}>
        {children}
      </Button>
      <DeleteModal onConfirm={onConfirm} isOpen={isOpen} onClose={onClose} />
    </>
  );
};
