import React from "react";
import { Text } from "@theme-ui/components";

import { SCHEDULERS_QUERY } from "@escola/graphql";
import { useFormater } from "@escola/utils";

import { SCHEDULER_ROUTES } from "./SchedulerRoutes";
import { BaseList } from "app/baseView";

export const SchedulerList = () => {
  const title = "Agenda";
  const query = SCHEDULERS_QUERY;
  const routes = SCHEDULER_ROUTES;
  const columns = React.useMemo(
    () => [
      {
        Header: null,
        id: "schedulers",
        columns: [
          {
            Header: "Nome",
            accessor: (row) =>
              row.class
                ? `Aula ${row.class.number} - ${row.class.subject.name}`
                : row.name,
            Cell: ({ row }) => {
              if (row.original.class) {
                return `Aula ${row.original.class.number} - ${row.original.class.subject.name}`;
              }
              return row.original.name;
            },
            sortType: "alphanumeric",
          },
          {
            Header: "Data",
            accessor: (row) => new Date(row.dateStart.replace(".000Z", "")),
            Cell: ({ row }) => {
              return (
                <Text sx={{ textTransform: "capitalize" }}>
                  {useFormater({
                    value: row.original.dateStart,
                    format: "EEEE, dd 'de' MMMM - HH:mm'h'",
                  })}
                </Text>
              );
            },
            sortType: "datetime",
          },
        ],
      },
    ],
    []
  );

  return (
    <BaseList
      query={query}
      columns={columns}
      routes={routes}
      title={title}
      queryItem="schedulers"
    />
  );
};

export default SchedulerList;
