import React from "react";
import { Box } from "@theme-ui/components";

import { PictureUploadField, TextField } from "@escola/fields";

import { useToken } from "app/authentication/hooks";

export const TeacherForm = ({ isDisabled = false }) => {
  const token = useToken();

  return (
    <Box>
      <TextField label="Nome" name="name" required />
      <PictureUploadField
        label="Foto"
        name="picture"
        placeholder="Carregue uma foto"
        token={token}
        required
      />
    </Box>
  );
};
