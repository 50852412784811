import { useQuery } from "@apollo/react-hooks";
import React, { useContext, useEffect } from "react";
import { Badge, Flex, Heading, Text } from "@theme-ui/components";

import { ME_QUERY, USERS_QUERY } from "@escola/graphql";

import { MONITOR_ROUTES } from "./MonitorRoutes";
import { BaseList } from "app/baseView";
import { Roles } from "app/layout/Sidebar";
import { LiveButton } from "app/live";
import { SocketConnectionContext } from "app/live/SocketConnection";

export const MonitorList = () => {
  const { data, loading } = useQuery(ME_QUERY);
  const { ws, studentsIds, usersIds, users } = useContext<any>(
    SocketConnectionContext
  );

  useEffect(() => {
    if (ws) {
      ws.emit("students:status");
      ws.emit("users:status");
    }
  }, [ws]);

  const title = "Monitor";
  const query = USERS_QUERY;
  const routes = MONITOR_ROUTES;
  const columns = React.useMemo(
    () => [
      {
        Header: null,
        id: "users",
        columns: [
          {
            Header: "Nome",
            accessor: "name",
            Cell: ({ row }) => (
              <Flex sx={{ flexDirection: "column" }}>
                <Text>{row.original.name}</Text>
                {row.original.alternativeStreaming && (
                  <Flex>
                    <Badge variant="danger">Com streaming alternativo</Badge>
                  </Flex>
                )}
              </Flex>
            ),
            enableFilters: true,
          },
          {
            Header: "Role",
            accessor: "role.name",
            Cell: ({ row }) => row.original.role.name,
            enableFilters: true,
          },
          {
            Header: " ",
            id: "online",
            accessor: (row) =>
              [...studentsIds, ...usersIds].indexOf(`${row.id}`) >= 0,
            Cell: ({ row }) => {
              const user = users.filter((user) => user.id === row.original.id);
              return (
                <Flex sx={{ alignItems: "center" }}>
                  {[...studentsIds, ...usersIds].indexOf(
                    `${row.original.id}`
                  ) >= 0 ? (
                    <>
                      <Badge variant="success.large">Online</Badge>
                      <LiveButton
                        sx={{
                          width: "15px",
                          height: "15px",
                          bg: "success",
                          ml: 2,
                        }}
                      />
                      {user?.map((u) => (
                        <Badge variant="small" sx={{ mr: 2 }}>
                          {u.local}
                        </Badge>
                      ))}
                    </>
                  ) : (
                    <Badge variant="muted.large">Offline</Badge>
                  )}
                </Flex>
              );
            },
          },
        ],
      },
    ],
    [studentsIds, usersIds]
  );

  if (loading) return null;
  const role = data.me.role.name;

  if (role !== Roles.Admin) return null;

  return (
    <>
      <Flex>
        <Heading sx={{ mr: 3 }}>Alunos: {studentsIds.length}</Heading>

        <Heading>Users: {[...new Set(usersIds)].length}</Heading>
      </Flex>
      <BaseList
        query={query}
        columns={columns}
        routes={routes}
        title={title}
        queryItem="users"
      />
    </>
  );
};

export default MonitorList;
