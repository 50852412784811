import { useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import css from "@styled-system/css";
import React, { useContext } from "react";
import { Box, Flex, Text } from "@theme-ui/components";

import { ME_QUERY } from "@escola/graphql";
import { Icon } from "@escola/icons";

import { useAuthentication } from "app/authentication/hooks";
import { AppContext, AppContextProps } from "app/context/Context";
import { LiveStatus } from "app/live";

export const Navbar = () => {
  const { isAuthenticated } = useAuthentication();
  const { data } = useQuery(ME_QUERY);
  const { sideBarOpen, setSideBarOpen } = useContext<AppContextProps>(
    AppContext
  );

  return (
    <Flex
      sx={{
        bg: "white",
        color: "black",
        height: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        px: 2,
        width: "100%",
      }}>
      <Flex
        sx={{
          alignItems: "center",
        }}>
        <Text sx={{ fontSize: [2, null, 3], ml: 2 }}>
          Bem-vindo(a) <b>{data?.me.name}</b>
        </Text>
      </Flex>
      <Flex sx={{ alignItems: "center" }}>
        <Box sx={{ display: ["none", null, "block"] }}>
          {isAuthenticated && <LiveStatus />}
        </Box>
      </Flex>

      <StyledMenu onClick={() => setSideBarOpen(!sideBarOpen)}>
        <Icon name={sideBarOpen ? "Close" : "Menu"} color="primary" size={30} />
      </StyledMenu>
    </Flex>
  );
};

const StyledMenu = styled.div`
  align-items: center;
  justify-content: center;

  ${css({
    ml: 6,
    display: ["flex", null, null, "none"],
  })}
`;
