import { Tooltip } from "@chakra-ui/core";
import React, { useContext, useEffect } from "react";
import { Badge, Flex, Text } from "@theme-ui/components";

import { STUDENTS_QUERY } from "@escola/graphql";
import { Icon } from "@escola/icons";
import { Status } from "@escola/layout";

import { STUDENT_ROUTES } from "./StudentRoutes";
import { BaseList } from "app/baseView";
import { LiveButton } from "app/live";
import { SocketConnectionContext } from "app/live/SocketConnection";

export const StudentList = () => {
  const { ws, studentsIds } = useContext<any>(SocketConnectionContext);

  useEffect(() => {
    if (ws) {
      ws.emit("students:status");
    }
  }, [ws]);

  const title = "Alunos";
  const query = STUDENTS_QUERY;
  const routes = STUDENT_ROUTES;
  const columns = React.useMemo(
    () => [
      {
        Header: null,
        id: "students",
        columns: [
          {
            Header: "Nome",
            accessor: "name",
            Cell: ({ row }) => (
              <Flex sx={{ flexDirection: "column" }}>
                <Text>{row.original.name}</Text>
              </Flex>
            ),
            enableFilters: true,
          },
          {
            Header: "Igreja",
            accessor: "church",
            Cell: ({ row }) => row.original.church,
            enableFilters: true,
          },
          {
            Header: "Estado",
            accessor: "blocked",
            Cell: ({ row }) => {
              return (
                <Flex>
                  {!row.original.blocked ? (
                    <Tooltip
                      label="Ativo"
                      placement="top"
                      aria-label="Ativo"
                      hasArrow>
                      <div>
                        <Status sx={{ bg: "success", color: "white" }} />
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      label="Pendente"
                      placement="top"
                      aria-label="Pendente"
                      hasArrow>
                      <div>
                        <Status sx={{ bg: "warning", color: "white" }} />
                      </div>
                    </Tooltip>
                  )}
                </Flex>
              );
            },
          },
          {
            Header: "Inscricão confirmada?",
            accessor: "confirmed",
            Cell: ({ row }) => {
              return (
                <Flex>
                  {row.original.confirmed ? (
                    <Flex sx={{ color: "success" }}>
                      <Icon name="Correct" />
                    </Flex>
                  ) : (
                    <Flex sx={{ color: "danger" }}>
                      <Icon name="Close" />
                    </Flex>
                  )}
                </Flex>
              );
            },
          },
          {
            Header: " ",
            id: "online",
            accessor: (row) => studentsIds.indexOf(`${row.id}`) >= 0,
            Cell: ({ row }) => {
              return (
                <Flex sx={{ alignItems: "center" }}>
                  {studentsIds.indexOf(`${row.original.id}`) >= 0 ? (
                    <>
                      <Badge variant="success.large">Online</Badge>
                      <LiveButton
                        sx={{
                          width: "15px",
                          height: "15px",
                          bg: "success",
                          ml: 2,
                        }}
                      />
                    </>
                  ) : (
                    <Badge variant="muted.large">Offline</Badge>
                  )}
                </Flex>
              );
            },
          },
        ],
      },
    ],
    [studentsIds]
  );

  return (
    <BaseList
      query={query}
      columns={columns}
      routes={routes}
      title={title}
      queryItem="users"
    />
  );
};

export default StudentList;
