import React from "react";
import { Switch, Route } from "react-router-dom";

import { SettingsView } from "./SettingsView";

export enum SETTINGS_ROUTES {
  list = `/settings`,
  edit = `/settings`,
}

export default () => (
  <Switch>
    <Route exact path={SETTINGS_ROUTES.edit} component={SettingsView} />
  </Switch>
);
