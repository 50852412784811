import React from "react";
import { Switch, Route } from "react-router-dom";

import { StudentAdd } from "./StudentAdd";
import { StudentList } from "./StudentList";
import { StudentView } from "./StudentView";

export enum STUDENT_ROUTES {
  list = `/alunos`,
  edit = `/alunos/:id`,
  add = `/alunos/novo`,
}

export default () => (
  <Switch>
    <Route exact path={STUDENT_ROUTES.list} component={StudentList} />
    <Route exact path={STUDENT_ROUTES.add} component={StudentAdd} />
    <Route exact path={STUDENT_ROUTES.edit} component={StudentView} />
  </Switch>
);
