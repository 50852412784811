"use strict";

exports.__esModule = true;
exports.default = void 0;

var _styled = _interopRequireDefault(require("@emotion/styled"));

var _themeGet = _interopRequireDefault(require("@styled-system/theme-get"));

var _Area = _interopRequireDefault(require("./Area"));

var _templateObject;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var MainLayout = (0, _styled.default)(_Area.default)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  grid-template-columns: 1fr;\n  grid-template-rows: ", " 1px 1fr;\n\n  grid-template-areas:\n    \"navbar\"\n    \"content\"\n    \"sidebar\";\n\n  ", " {\n    height: 100vh;\n    grid-template-columns: ", " 1fr;\n    grid-template-rows: ", " 1fr;\n\n    grid-template-areas:\n      \"sidebar navbar\"\n      \"sidebar content\";\n  }\n"])), (0, _themeGet.default)("sizes.navbar"), function (_ref) {
  var theme = _ref.theme;
  return "@media screen and (min-width: ".concat(theme.breakpoints[2], ")");
}, (0, _themeGet.default)("sizes.sidebar"), (0, _themeGet.default)("sizes.navbar"));
var _default = MainLayout;
exports.default = _default;