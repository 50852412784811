import React from "react";
import { Switch, Route } from "react-router-dom";

import { MonitorList } from "./MonitorList";

export enum MONITOR_ROUTES {
  list = `/monitor`,
}

export default () => (
  <Switch>
    <Route exact path={MONITOR_ROUTES.list} component={MonitorList} />
  </Switch>
);
