"use strict";

exports.__esModule = true;
exports.currentConfig = exports.config = void 0;
var config = {
  development: {
    API: "http://localhost:1339",
    PUBLIC_URL: "/",
    WS_ENDPOINT: "http://localhost:1339",
    UPLOAD_PREFIX: "http://localhost:1339",
    CERTIFICATE_URL: "http://0.0.0.0:9000/alunos/eb-virtual/certificado/"
  },
  test: {},
  production: {
    API: "https://api.escolabiblica.msbnportugal.com",
    PUBLIC_URL: "/",
    WS_ENDPOINT: "https://api.escolabiblica.msbnportugal.com",
    UPLOAD_PREFIX: "https://api.escolabiblica.msbnportugal.com",
    CERTIFICATE_URL: "https://eb.msbnportugal.com/alunos/eb-virtual/certificado/"
  }
};
exports.config = config;

var currentConfig = function () {
  if (process.env.NODE_ENV) {
    return config[process.env.NODE_ENV];
  }

  return null;
}();

exports.currentConfig = currentConfig;