import { useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import css from "@styled-system/css";
import Logo from "assets/logo.png";
import React, { useContext } from "react";
import { Flex, Text, Image } from "@theme-ui/components";

import { ME_QUERY } from "@escola/graphql";
import { Icon } from "@escola/icons";
import { Link } from "@escola/layout";

import { useAuthentication } from "app/authentication/hooks";
import { Logout } from "app/authentication/Logout";
import { AppContext, AppContextProps } from "app/context/Context";
import { LiveStatus } from "app/live";

export enum Roles {
  Secretaria = "Secretaria",
  Midia = "Midia",
  Admin = "Admin",
}

export const Sidebar = () => {
  const { isAuthenticated } = useAuthentication();
  const { data, loading } = useQuery(ME_QUERY);
  const { sideBarOpen: open } = useContext<AppContextProps>(AppContext);

  if (loading) return null;

  const role = data.me.role.name;

  return (
    <StyledSidebar
      sx={{
        left: open ? 0 : "-100%",
      }}>
      <Flex
        sx={{
          p: 4,
          flexDirection: "column",
          position: "relative",
          justifyItems: "center",
          alignItems: "stretch",
        }}>
        <Text
          sx={{
            fontSize: [1, null, 2],
            fontWeight: "bold",
            textAlign: "center",
            textTransform: "uppercase",
            mb: 2,
          }}>
          Gestor Escola Bíblica
        </Text>
        <Image
          src={Logo}
          sx={{
            bg: "white",
            p: 3,
            borderRadius: 5,
            mx: "auto",
            mb: 4,
            border: "1px solid",
            borderColor: "gray-300",
            width: "150px",
          }}
        />
        <Flex
          sx={{
            width: "100%",
            display: ["flex", null, "none"],
            mb: [4, null, 0],
            justifyContent: "center",
          }}>
          {isAuthenticated && <LiveStatus />}
        </Flex>
        <Link to="/">
          <MenuItem
            sx={{ alignItems: "center", textDecoration: "none" }}
            variant="styles.a"
            as="a">
            <Icon name="Dashboard" size={17} />
            <Text pl={2}>Dashboard</Text>
          </MenuItem>
        </Link>
        <Link to="/aulas">
          <MenuItem
            sx={{ alignItems: "center", textDecoration: "none" }}
            variant="styles.a"
            as="a">
            <Icon name="Class" size={17} />
            <Text pl={2}>Aulas</Text>
          </MenuItem>
        </Link>
        {(role === Roles.Secretaria || role === Roles.Admin) && (
          <>
            <Link to="/alunos">
              <MenuItem
                sx={{ alignItems: "center", textDecoration: "none" }}
                variant="styles.a"
                as="a">
                <Icon name="Student" size={17} />
                <Text pl={2}>Alunos</Text>
              </MenuItem>
            </Link>
            <Link to="/agenda">
              <MenuItem
                sx={{ alignItems: "center", textDecoration: "none" }}
                variant="styles.a"
                as="a">
                <Icon name="Calendar" size={17} />
                <Text pl={2}>Agenda</Text>
              </MenuItem>
            </Link>
            <Link to="/disciplinas">
              <MenuItem
                sx={{ alignItems: "center", textDecoration: "none" }}
                variant="styles.a"
                as="a">
                <Icon name="Book" size={17} />
                <Text pl={2}>Disciplinas</Text>
              </MenuItem>
            </Link>
            <Link to="/professores">
              <MenuItem
                sx={{ alignItems: "center", textDecoration: "none" }}
                variant="styles.a"
                as="a">
                <Icon name="Teacher" size={17} />
                <Text pl={2}>Professores</Text>
              </MenuItem>
            </Link>
            <Link to="/assiduidade">
              <MenuItem
                sx={{ alignItems: "center", textDecoration: "none" }}
                variant="styles.a"
                as="a">
                <Icon name="Correct" size={17} />
                <Text pl={2}>Assiduidade</Text>
              </MenuItem>
            </Link>
            <Link to="/settings">
              <MenuItem
                sx={{ alignItems: "center", textDecoration: "none" }}
                variant="styles.a"
                as="a">
                <Icon name="Settings" size={17} />
                <Text pl={2}>Definições</Text>
              </MenuItem>
            </Link>
          </>
        )}
        {(role === Roles.Midia || role === Roles.Admin) && (
          <Link to="/live">
            <MenuItem
              sx={{ alignItems: "center", textDecoration: "none" }}
              variant="styles.a"
              as="a">
              <Icon name="VideoCamera" size={17} />
              <Text pl={2}>Transmissåo</Text>
            </MenuItem>
          </Link>
        )}
        {role === Roles.Admin && (
          <Link to="/monitor">
            <MenuItem
              sx={{ alignItems: "center", textDecoration: "none" }}
              variant="styles.a"
              as="a">
              <Icon name="View" size={17} />
              <Text pl={2}>Monitor</Text>
            </MenuItem>
          </Link>
        )}
        <Line />
        <Flex>{isAuthenticated && <Logout />}</Flex>
      </Flex>
    </StyledSidebar>
  );
};

const StyledSidebar = styled(Flex)`
  width: 100%;
  position: fixed;
  top: 60px;
  z-index: 2;
  transition: all ease-in 0.2s;
  left: 0;
  flex-direction: column;
  align-items: center;
  bottom: 0;
  overflow: auto;

  ${({ sx }) => css({ backgroundColor: "gray-100", ...sx })}

  ${({ theme }: any) =>
    `@media screen and (min-width: ${theme.breakpoints[2]})`} {
    top: 0;
    position: sticky;
  }
`;

const MenuItem = styled(Flex)`
  align-items: center;
  padding: 0 0 8px 0;
  cursor: pointer;

  ${css({
    color: "gray-600",
    fontSize: ["20px", null, "16px"],
    paddingBottom: ["16px", null, "8px"],
  })}

  :hover {
    color: black;
  }

  &.active {
    color: black;
  }
`;

const Line = styled.div`
  width: 100%;
  padding: 5px 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;

  ${css({
    borderBottomColor: "gray-300",
  })}
`;
