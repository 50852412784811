"use strict";

exports.__esModule = true;
exports.fileNotRequired = exports.file = exports.dateNotRequired = exports.dateTime = exports.date = void 0;

var _dateFns = require("date-fns");

var _yup = require("yup");

var date = function date() {
  return (0, _yup.mixed)().required("Obrigatório").test("invalidDate", "Data inválida", function (value) {
    try {
      var _date = (0, _dateFns.parse)(value, "dd/MM/yyyy", new Date());

      (0, _dateFns.format)(_date, "dd/MM/yyyy");

      if (_date > new Date() || _date < new Date("01-01-1900")) {
        return false;
      }
    } catch (e) {
      return false;
    }

    return true;
  });
};

exports.date = date;

var dateTime = function dateTime() {
  return (0, _yup.mixed)().test("invalidDate", "Data inválida", function (value) {
    try {
      var _date2 = (0, _dateFns.parse)(value, "dd/MM/yyyy HH:mm", new Date());

      (0, _dateFns.format)(_date2, "dd/MM/yyyy H:mm");

      if (_date2 < new Date("01-01-1900")) {
        return false;
      }
    } catch (e) {
      return false;
    }

    return true;
  });
};

exports.dateTime = dateTime;

var dateNotRequired = function dateNotRequired() {
  return (0, _yup.mixed)().test("invalidDate", "Data inválida", function (value) {
    if (!value || value === "__/__/____ __:__") return true;

    try {
      var _date3 = (0, _dateFns.parse)(value, "dd/MM/yyyy HH:mm", new Date());

      (0, _dateFns.format)(_date3, "dd/MM/yyyy H:mm");
    } catch (e) {
      return false;
    }

    return true;
  });
};

exports.dateNotRequired = dateNotRequired;

var file = function file(_ref) {
  var MAX_SIZE = _ref.MAX_SIZE,
      SUPPORTED_FORMATS = _ref.SUPPORTED_FORMATS;
  return (0, _yup.mixed)().required("Obrigatório").test("fileFormat", "Formato não suportado", function (value) {
    return value && SUPPORTED_FORMATS.includes(value.mime);
  }).test("fileSize", "Ficheiro muito grande", function (value) {
    return value && value.size <= MAX_SIZE;
  });
};

exports.file = file;

var fileNotRequired = function fileNotRequired(_ref2) {
  var MAX_SIZE = _ref2.MAX_SIZE,
      SUPPORTED_FORMATS = _ref2.SUPPORTED_FORMATS;
  return (0, _yup.mixed)().test("fileFormat", "Formato não suportado", function (value) {
    return value && value.mime ? SUPPORTED_FORMATS.includes(value.mime) : value ? false : true;
  }).test("fileSize", "Ficheiro muito grande", function (value) {
    return value && value.size ? value.size <= MAX_SIZE : value ? false : true;
  });
};

exports.fileNotRequired = fileNotRequired;