import { ApolloProvider } from "@apollo/react-hooks";
import { ThemeProvider } from "@chakra-ui/core";
import { theme as initialTheme } from "@chakra-ui/core";
import { Box } from "@theme-ui/components";
import Bg from "assets/blur-bg.jpg";
import React, { Suspense, useState } from "react";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";

import {
  SpinnerAbsolute,
  MainLayout,
  NavbarArea,
  ContentArea,
  SidebarArea,
  EmptyLayout,
} from "@escola/layout";
import { theme as defaultTheme } from "@escola/themes";
import { GlobalStyles } from "@escola/themes";

import { AttendanceRoutes } from "./attendance";
import { AulaRoutes } from "./aula";
import { AuthenticationRoutes, ProtectedRoute } from "./authentication";
import { AUTHENTICATION_ROUTES } from "./authentication/AuthenticationRoutes";
import { useAuthentication } from "./authentication/hooks";
import { AppContext } from "./context/Context";
import { DashboardRoutes } from "./dashboard";
import { LiveRoutes } from "./live";
import { SocketConnection } from "./live/SocketConnection";
import { MonitorRoutes } from "./monitor";
import { NotificationRoutes, Notifications } from "./notifications";
import { SchedulerRoutes } from "./scheduler";
import { SettingsRoutes } from "./settings";
import { StudentRoutes } from "./students";
import { SubjectRoutes } from "./subjects";
import { TeacherRoutes } from "./teachers";
import { useApollo } from "./utils/useApollo";
import { Navbar, Sidebar } from "app/layout";

const ScrollTo = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  return null;
};

export const LoginContainer = () => {
  return (
    <EmptyLayout sx={{ bg: "transparent" }}>
      <ContentArea sx={{ bg: "transparent" }}>
        <Route component={AuthenticationRoutes} />
      </ContentArea>
      <Box
        sx={{
          position: "fixed",
          width: "100%",
          height: "100%",
          top: 0,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          zIndex: -1,
          backgroundImage: `url(${Bg})`,
        }}
      />
    </EmptyLayout>
  );
};

export const AppContainer = () => {
  const { isAuthenticated } = useAuthentication();
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const history = useHistory();

  history.listen(() => {
    setSideBarOpen(false);
  });

  if (!isAuthenticated) return;

  return (
    <Suspense fallback={<SpinnerAbsolute />}>
      <SocketConnection>
        <AppContext.Provider
          value={{
            sideBarOpen,
            setSideBarOpen,
          }}>
          <MainLayout>
            <NavbarArea>
              <Navbar />
            </NavbarArea>
            <SidebarArea>
              <Sidebar />
            </SidebarArea>
            <ContentArea>
              <DashboardRoutes />
              <AulaRoutes />
              <SubjectRoutes />
              <TeacherRoutes />
              <SchedulerRoutes />
              <StudentRoutes />
              <LiveRoutes />
              <SettingsRoutes />
              <AttendanceRoutes />
              <NotificationRoutes />
              <MonitorRoutes />
            </ContentArea>
          </MainLayout>
        </AppContext.Provider>
        <Notifications />
      </SocketConnection>
    </Suspense>
  );
};

export const theme: any = { ...initialTheme, ...defaultTheme };
export const App = () => {
  const client = useApollo();

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <GlobalStyles theme={theme} />
        <BrowserRouter>
          <Route component={ScrollTo} />
          <Switch>
            <Route
              path={AUTHENTICATION_ROUTES.base}
              component={LoginContainer}
            />
            <ProtectedRoute component={AppContainer} />
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  );
};
