import { useQuery } from "@apollo/react-hooks";
import { Flex, Text, Box, Badge } from "@theme-ui/components";
import React, { useContext, useEffect, useState } from "react";

import { STUDENTS_QUERY } from "@escola/graphql";
import { GridLayout } from "@escola/layout";

import { LiveButton } from "app/live";
import { SocketConnectionContext } from "app/live/SocketConnection";

const Block = ({ sx = {}, ...props }) => (
  <Flex
    sx={{
      bg: "white",
      p: 4,
      m: 0,
      flexDirection: "column",
      justifyItems: "center",
      alignItems: "center",
      mb: 3,
      border: "1px solid #DCDCDC",
      borderRadius: 4,
      print: {
        bg: "white",
        p: 0,
        mb: 1,
        boxShadow: "none",
      },
      ...sx,
    }}
    {...props}
  />
);

const Title = ({ sx = {}, ...props }) => (
  <Text
    variant="styles.h2"
    sx={{ mt: 0, mb: 3, textAlign: "center", ...sx }}
    {...props}
  />
);

const Number = ({ sx = {}, ...props }) => (
  <Badge
    variant="rounded"
    sx={{
      m: 0,
      px: 2,
      fontSize: 5,
      bg: "primary",
      color: "white",
      width: "auto",
      height: "50px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      ml: "4",
      ...sx,
    }}
    {...props}
  />
);

export const Dashboard = () => {
  const { ws, students } = useContext<any>(SocketConnectionContext);
  const { data } = useQuery(STUDENTS_QUERY);

  const activeStudents = data?.users.filter((user) => !user.blocked);

  useEffect(() => {
    if (ws) {
      ws.emit("students:status");
    }
  }, [ws]);

  return (
    <Box sx={{ height: "100%" }}>
      <GridLayout>
        <Block>
          <Title>Alunos Conectados</Title>
          <Flex
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}>
            <LiveButton sx={{ width: "30px", height: "30px", bg: "success" }} />
            <Number sx={{ bg: "success" }}>{students.length}</Number>
          </Flex>
        </Block>
        <Block>
          <Title>Alunos Inscritos</Title>
          <Number>{data?.users.length || 0}</Number>
        </Block>
        <Block>
          <Title>Alunos com conta ativa</Title>
          <Number>{activeStudents?.length || 0}</Number>
        </Block>
      </GridLayout>
    </Box>
  );
};

export default Dashboard;
