import React from "react";

import {
  LIVE_QUERY,
  UPDATE_LIVE_MUTATION
} from "@escola/graphql";

import { LiveForm } from "./LiveForm";
import { LIVE_ROUTES } from "./LiveRoutes";
import {
  validationSchema,
  deserializeLive,
  serializeLive
} from "./LiveUtils";
import { options } from "./LiveUtils";
import {  UniqueView } from "app/baseView";

export const LiveView = (props) => {
  const title = "Editar Transmissão";
  const id = props.match.params.id;
  const query = LIVE_QUERY;
  const routes = LIVE_ROUTES;
  const queryItem = "live";
  const updateMutation = UPDATE_LIVE_MUTATION;
  const deserialize = deserializeLive;
  const serialize = serializeLive;
  const updateInput = "live";
  const schema = validationSchema;
  const ItemForm = LiveForm;
  const mutationOptions = options;

  return (
    <UniqueView
      id={id}
      title={title}
      query={query}
      updateMutation={updateMutation}
      routes={routes}
      queryItem={queryItem}
      deserialize={deserialize}
      serialize={serialize}
      updateInput={updateInput}
      validationSchema={schema}
      ItemForm={ItemForm}
      mutationOptions={mutationOptions}
    />
  );
};

export default LiveView;
