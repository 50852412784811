import { useQuery, useMutation } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { Formik, Form } from "formik";
import React, { useState, useEffect } from "react";
import { Box, Text, Button, Label, Flex } from "@theme-ui/components";

import { SpinnerAbsolute } from "@escola/layout";

import { Card } from "app/layout/Card";
import { useAlertSuccess } from "app/utils";

export const UniqueView = ({
  id,
  title,
  query,
  routes,
  updateMutation,
  queryItem,
  serialize,
  deserialize,
  updateInput,
  validationSchema,
  ItemForm,
  mutationOptions,
}: any) => {
  const [state, setState] = useState<any>({});
  const { data, loading, refetch } = useQuery(query);

  const [upadateData, { ...update }] = useMutation(
    updateMutation,
    mutationOptions
  );
  const isDisabled = loading || update.loading;
  const alertSuccess = useAlertSuccess();

  useEffect(() => {
    if (data?.[queryItem]?.id) {
      const values = deserialize(data[queryItem]);
      setState(values);
    }
  }, [data]);

  const updateHandler = (data) => {
    const values = serialize(data);
    upadateData({
      variables: {
        [updateInput]: { data: values },
      },
    })
      .then(() => {
        refetch();
        alertSuccess("Item autalizado com sucesso.");
      })
      .catch((e) => {
        Sentry.withScope(function (scope) {
          scope.setLevel(Sentry.Severity.Error);
          Sentry.captureException(e);
        });
      });
  };

  return (
    <>
      {loading && <SpinnerAbsolute />}
      {state?.id && (
        <Card
          sx={{
            justifyContent: "space-between",
            position: "sticky",
            top: 0,
            zIndex: 2,
          }}>
          <Text variant="styles.h2" sx={{ mb: 4 }}>
            {title}
          </Text>

          <Formik
            validateOnMount={false}
            initialValues={state}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              updateHandler(values);
            }}>
            {({ isValid }) => {
              return (
                <Form autoComplete="off" noValidate={true}>
                  <fieldset
                    disabled={isDisabled}
                    style={{ border: "none", padding: 0 }}>
                    <ItemForm isDisabled={isDisabled} />
                    <Box
                      sx={{
                        position: "fixed",
                        bottom: "0",
                        left: [0, null, "200px"],
                        right: 0,
                      }}>
                      <Card
                        sx={{
                          display: "flex",
                          mb: 0,
                          bg: "white",
                          width: "100%",
                          py: 3,
                          px: 4,
                          justifyContent: "flex-end",
                        }}>
                        {update.loading && <SpinnerAbsolute />}
                        <Box>
                          {update.error && (
                            <Label
                              variant="label.error"
                              sx={{
                                justifyContent: "center",
                                alignItems: "center",
                              }}>
                              Erro ao guardar, tente novamente...
                            </Label>
                          )}
                        </Box>
                        <Flex sx={{ alignItems: "center" }}>
                          <Button
                            type="submit"
                            variant="primary"
                            disabled={!isValid || isDisabled}>
                            Guardar
                          </Button>
                        </Flex>
                      </Card>
                    </Box>
                  </fieldset>
                </Form>
              );
            }}
          </Formik>
        </Card>
      )}
    </>
  );
};
