import React from "react";

import { IS_OPEN, UPDATE_IS_OPEN_MUTATION } from "@escola/graphql";

import { SettingsForm } from "./SettingsForm";
import { SETTINGS_ROUTES } from "./SettingsRoutes";
import {
  validationSchema,
  deserializeLive,
  serializeLive,
} from "./SettingsUtils";
import { options } from "./SettingsUtils";
import { UniqueView } from "app/baseView";

export const SettingsView = (props) => {
  const title = "Definições";
  const query = IS_OPEN;
  const routes = SETTINGS_ROUTES;
  const queryItem = "isOpen";
  const updateMutation = UPDATE_IS_OPEN_MUTATION;
  const deserialize = deserializeLive;
  const serialize = serializeLive;
  const updateInput = "isOpen";
  const schema = validationSchema;
  const ItemForm = SettingsForm;
  const mutationOptions = options;

  return (
    <UniqueView
      title={title}
      query={query}
      updateMutation={updateMutation}
      routes={routes}
      queryItem={queryItem}
      deserialize={deserialize}
      serialize={serialize}
      updateInput={updateInput}
      validationSchema={schema}
      ItemForm={ItemForm}
      mutationOptions={mutationOptions}
    />
  );
};
