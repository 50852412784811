import React from "react";
import { Switch, Route } from "react-router-dom";

import { LiveView } from "./LiveView";

export enum LIVE_ROUTES {
  list = `/live`,
  edit = `/live`,
}

export default () => (
  <Switch>
    <Route exact path={LIVE_ROUTES.edit} component={LiveView} />
  </Switch>
);
