import React from "react";

import { CREATE_TEACHER_MUTATION } from "@escola/graphql";

import { TeacherForm } from "./TeacherForm";
import { TEACHER_ROUTES } from "./TeacherRoutes";
import {
  validationSchema,
  options,
  initialValues,
  serializeTeacher,
} from "./TeacherUtils";
import { BaseAdd } from "app/baseView";

export const TeacherAdd = () => {
  const title = "Novo Professor";
  const createMutation = CREATE_TEACHER_MUTATION;
  const routes = TEACHER_ROUTES;
  const createInput = "createTeacher";
  const createItemInput = "createTeacherInput";
  const schema = validationSchema;
  const ItemForm = TeacherForm;
  const itemName = "teacher";
  const serialize = serializeTeacher;

  return (
    <BaseAdd
      title={title}
      createMutation={createMutation}
      routes={routes}
      createInput={createInput}
      createItemInput={createItemInput}
      validationSchema={schema}
      ItemForm={ItemForm}
      mutationOptions={options}
      initialValues={initialValues}
      itemName={itemName}
      serialize={serialize}
    />
  );
};

export default TeacherAdd;
