import React from "react";

import {
  DELETE_SCHEDULER_MUTATION,
  UPDATE_SCHEDULER_MUTATION,
  SCHEDULER_QUERY,
} from "@escola/graphql";

import { SchedulerDelete } from "./SchedulerDelete";
import { SchedulerForm } from "./SchedulerForm";
import { SCHEDULER_ROUTES } from "./SchedulerRoutes";
import {
  validationSchema,
  deserializeScheduler,
  serializeScheduler,
} from "./SchedulerUtils";
import { options } from "./SchedulerUtils";
import { BaseView } from "app/baseView";

export const SchedulerView = (props) => {
  const title = "Editar Item da Agenda";
  const id = props.match.params.id;
  const query = SCHEDULER_QUERY;
  const updateMutation = UPDATE_SCHEDULER_MUTATION;
  const deleteMutation = DELETE_SCHEDULER_MUTATION;
  const routes = SCHEDULER_ROUTES;
  const queryItem = "schedulers";
  const deserialize = deserializeScheduler;
  const serialize = serializeScheduler;
  const updateInput = "updateSchedulerInput";
  const deleteInput = "deleteSchedulerInput";
  const schema = validationSchema;
  const ItemDelete = SchedulerDelete;
  const ItemForm = SchedulerForm;
  const mutationOptions = options;

  return (
    <BaseView
      id={id}
      title={title}
      query={query}
      updateMutation={updateMutation}
      deleteMutation={deleteMutation}
      routes={routes}
      queryItem={queryItem}
      deserialize={deserialize}
      serialize={serialize}
      updateInput={updateInput}
      deleteInput={deleteInput}
      validationSchema={schema}
      ItemDelete={ItemDelete}
      ItemForm={ItemForm}
      mutationOptions={mutationOptions}
    />
  );
};

export default SchedulerView;
