import React from "react";

import {
  DELETE_TEACHER_MUTATION,
  UPDATE_TEACHER_MUTATION,
  TEACHER_QUERY,
} from "@escola/graphql";

import { TeacherDelete } from "./TeacherDelete";
import { TeacherForm } from "./TeacherForm";
import { TEACHER_ROUTES } from "./TeacherRoutes";
import {
  validationSchema,
  deserializeTeacher,
  serializeTeacher,
} from "./TeacherUtils";
import { options } from "./TeacherUtils";
import { BaseView } from "app/baseView";

export const TeacherView = (props) => {
  const title = "Editar Professor";
  const id = props.match.params.id;
  const query = TEACHER_QUERY;
  const updateMutation = UPDATE_TEACHER_MUTATION;
  const deleteMutation = DELETE_TEACHER_MUTATION;
  const routes = TEACHER_ROUTES;
  const queryItem = "teachers";
  const deserialize = deserializeTeacher;
  const serialize = serializeTeacher;
  const updateInput = "updateTeacherInput";
  const deleteInput = "deleteTeacherInput";
  const schema = validationSchema;
  const ItemDelete = TeacherDelete;
  const ItemForm = TeacherForm;
  const mutationOptions = options;

  return (
    <BaseView
      id={id}
      title={title}
      query={query}
      updateMutation={updateMutation}
      deleteMutation={deleteMutation}
      routes={routes}
      queryItem={queryItem}
      deserialize={deserialize}
      serialize={serialize}
      updateInput={updateInput}
      deleteInput={deleteInput}
      validationSchema={schema}
      ItemDelete={ItemDelete}
      ItemForm={ItemForm}
      mutationOptions={mutationOptions}
    />
  );
};

export default TeacherView;
